<section id="header">
    <span id="title">
        Agregar imagen
    </span>
</section>
<section id="main-section" class="p-6">
    <form class="flex gap-3">
        <mat-form-field class="flex-1">
            <mat-icon matPrefix>link</mat-icon>
            <input #urlInput matInput placeholder="Inserta la URL de la imagen a subir">
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="confirmImageUrl(urlInput.value)">
            <mat-icon>check</mat-icon>
            &nbsp;
            Verificar
        </button>
    </form>
    <ng-container *ngIf="selectedUrlImage">
        <mat-divider class="mb-4"></mat-divider>
        <div class="flex justify-between">
            <div class="relative flex flex-col gap-4 mr-2">
                <span class="label">Imagen seleccionada</span>
                <button mat-flat-button color="warn" class="absolute bottom-0" (click)="selectedUrlImage = null">
                    <mat-icon>close</mat-icon>
                    &nbsp;
                    Remover imagen
                </button>
            </div>
            <img class="w-6/12 rounded-md" [src]="selectedUrlImage">
        </div>
    </ng-container>
</section>

<section id="action-buttons" class="buttons justify-end">
    <button mat-button color="warn" class="mr-2" mat-dialog-close>{{ "CANCEL" | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="!selectedUrlImage" (click)="addImages()">
      Agregar imagen
    </button>
</section>