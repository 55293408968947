<section id="header">
  <span id="title">{{ 'SHARED.DIALOGS.SELECT_PROVIDER' | translate }}</span>
  <!-- <button (click)="this.cancel()">
        <mat-icon [svgIcon]="'close'"></mat-icon>
    </button> -->
</section>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<section id="main-section">
  <mat-form-field class="w-full mb-4">
    <mat-icon matPrefix [svgIcon]="'search'"></mat-icon>
    <input
      matInput
      class="w-full"
      autocomplete="off"
      placeholder="{{ 'PLACEHOLDERS.SEARCH_PROVIDER' | translate }}"
      [formControl]="searchProviderInputControl"
    />
  </mat-form-field>
  <ng-container *ngIf="this.providers.length; else noProviders">
    <table mat-table multiTemplateDataRows [dataSource]="this.providers">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let provider">
          <mat-radio-button
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(provider) : null"
            [checked]="selection.isSelected(provider)"
          >
          </mat-radio-button>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef disableClear>
          {{ 'SHARED.DIALOGS.NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let provider">
          {{ provider.name }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="providerId">
          <th mat-header-cell *matHeaderCellDef disableClear>ID</th>
          <td mat-cell *matCellDef="let provider">
            {{ provider.providerId }}
          </td>
        </ng-container> -->
      <ng-container matColumnDef="internalCode">
        <th mat-header-cell *matHeaderCellDef disableClear>
          {{ 'SHARED.DIALOGS.INTERNAL_CODE' | translate }}
        </th>
        <td mat-cell *matCellDef="let provider">
          {{ provider.internalCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef disableClear>
          {{ 'SHARED.DIALOGS.COUNTRY' | translate }}
        </th>
        <td mat-cell *matCellDef="let provider">
          <span class="flex items-center">
            <span class="min-w-4">{{ provider.country?.label }}</span>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="providersTableColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let provider; columns: providersTableColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      *ngIf="this.providers.length"
      [length]="paginationLength"
      (page)="this.getProviders($event)"
      [pageIndex]="this.pageIndex"
      [pageSize]="pageSize"
      aria-label="Select page"
    ></mat-paginator>
  </ng-container>
  <ng-template #noProviders>
    <div class="no-providers">{{ 'SHARED.DIALOGS.NO_PRODUCTS' | translate }}</div>
  </ng-template>
</section>
<section id="action-buttons" class="buttons justify-end gap-2">
  <button mat-button color="warn" (click)="cancel()">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-flat-button color="primary" [disabled]="!selection?.selected?.length" (click)="accept()">
    {{ 'CONFIRM' | translate }}
  </button>
</section>
