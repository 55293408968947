import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

export const activityFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    serviceTitle: ['', [Validators.required]],
    startTime: [null, []],
    duration: [null, []],
    status: [null, []],
    optional: [0, []],
    survey: [null, []],
    useItineraryGroups: [true, []],
    comments: [null, []],
    description: [null, []],
    ownDescription: [false, []]
  });
};