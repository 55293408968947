import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Group, Pagination, Passenger, SummaryCard } from '../types/objects';

@Injectable({
  providedIn: 'root'
})
export class TripGroupsCommunicationService {
  private _tripGroupsByGroup: BehaviorSubject<SummaryCard[] | null> = new BehaviorSubject(null);
  private _paginationByGroup: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _groupPassengers: BehaviorSubject<Passenger[] | null> = new BehaviorSubject(null);
  private _groupPagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _tripGroupsByCity: BehaviorSubject<SummaryCard[] | null> = new BehaviorSubject(null);
  private _paginationByCity: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _cityGroupPassengers: BehaviorSubject<Passenger[] | null> = new BehaviorSubject(null);
  private _cityGroupPagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _tripGroupsByItinerary: BehaviorSubject<SummaryCard[] | null> = new BehaviorSubject(null);
  private _paginationByItinerary: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _itineraryGroupPassengers: BehaviorSubject<Passenger[] | null> = new BehaviorSubject(null);
  private _itineraryGroupPagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _tripGroupsByStatus: BehaviorSubject<SummaryCard[] | null> = new BehaviorSubject(null);
  private _paginationByStatus: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _statusGroupPassengers: BehaviorSubject<Passenger[] | null> = new BehaviorSubject(null);
  private _statusGroupPagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  constructor() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Observable collections
  // -----------------------------------------------------------------------------------------------------

  get tripGroupsByGroup$(): Observable<SummaryCard[]> {
    return this._tripGroupsByGroup.asObservable();
  }

  get groupPassengers$(): Observable<Passenger[]> {
    return this._groupPassengers.asObservable();
  }

  get tripGroupsByCity$(): Observable<SummaryCard[]> {
    return this._tripGroupsByCity.asObservable();
  }

  get cityGroupPassengers$(): Observable<Passenger[]> {
    return this._cityGroupPassengers.asObservable();
  }

  get tripGroupsByItinerary$(): Observable<SummaryCard[]> {
    return this._tripGroupsByItinerary.asObservable();
  }

  get itineraryGroupPassengers$(): Observable<Passenger[]> {
    return this._itineraryGroupPassengers.asObservable();
  }

  get tripGroupsByStatus$(): Observable<SummaryCard[]> {
    return this._tripGroupsByStatus.asObservable();
  }

  get statusGroupPassengers$(): Observable<Passenger[]> {
    return this._statusGroupPassengers.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Observable pagination
  // -----------------------------------------------------------------------------------------------------

  get paginationByGroup$(): Observable<Pagination> {
    return this._paginationByGroup.asObservable();
  }

  get groupPagination$(): Observable<Pagination> {
    return this._groupPagination.asObservable();
  }

  get paginationByCity$(): Observable<Pagination> {
    return this._paginationByCity.asObservable();
  }

  get cityGroupPagination$(): Observable<Pagination> {
    return this._cityGroupPagination.asObservable();
  }

  get paginationByItinerary$(): Observable<Pagination> {
    return this._paginationByItinerary.asObservable();
  }

  get itineraryGroupPagination$(): Observable<Pagination> {
    return this._itineraryGroupPagination.asObservable();
  }

  get paginationByStatus$(): Observable<Pagination> {
    return this._paginationByStatus.asObservable();
  }

  get statusGroupPagination$(): Observable<Pagination> {
    return this._statusGroupPagination.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ get Values
  // -----------------------------------------------------------------------------------------------------

  get getTripGroupsByGroupValue(): SummaryCard[] {
    return this._tripGroupsByGroup.value;
  }

  get getPaginationByGroupValue(): Pagination {
    return this._paginationByGroup.value;
  }

  get getTripGroupsByCityValue(): SummaryCard[] {
    return this._tripGroupsByCity.value;
  }

  get getPaginationByCityValue(): Pagination {
    return this._paginationByCity.value;
  }

  get getCityGroupPassengersValue(): Passenger[] {
    return this._cityGroupPassengers.value;
  }

  get getTripGroupsByItineraryValue(): SummaryCard[] {
    return this._tripGroupsByItinerary.value;
  }

  get getPaginationByItineraryValue(): Pagination {
    return this._paginationByItinerary.value;
  }

  get getItineraryGroupPassengersValue(): Passenger[] {
    return this._itineraryGroupPassengers.value;
  }

  get getTripGroupsByStatusValue(): SummaryCard[] {
    return this._tripGroupsByStatus.value;
  }

  get getPaginationByStatusValue(): Pagination {
    return this._paginationByStatus.value;
  }

  get getStatusGroupPassengersValue(): Passenger[] {
    return this._statusGroupPassengers.value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ next collections & paginations
  // -----------------------------------------------------------------------------------------------------

  nextTripGroupsBy(groups: SummaryCard[]): void {
    this._tripGroupsByGroup.next(groups);
  }

  nextPaginationBy(total: number, size: number, page: number): void {
    const pagination = {
      length: total,
      size,
      page,
      lastPage: Math.ceil((total) / size),
      startIndex: size * (page - 1) + 1,
      endIndex: Math.min(total, size * page)
    };
    this._paginationByGroup.next(pagination);
  }


  nextGroupPassengers(groupType: string, groupPassengers: Passenger[]): void {
    this._groupPassengers.next(groupPassengers);
  }

  nextGroupPagination(groupType: string, pagination: Pagination): void {
    switch (groupType) {
      case 'group':
        this._groupPagination.next(pagination);
        break;
      case 'city':
        this._cityGroupPagination.next(pagination);
        break;
      case 'itinerary':
        this._itineraryGroupPagination.next(pagination);
        break;
      case 'status':
        this._statusGroupPagination.next(pagination);
        break;
      default:
    }
  }
}
