import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Pagination, Itinerary } from '../types/objects';

@Injectable()
export class ItinerariesCommunicationService {
  private _itineraries: BehaviorSubject<Itinerary[] | null> = new BehaviorSubject(null);
  private _pagination: Subject<Pagination> = new Subject();

  constructor() {
  }

  get itineraries$(): Observable<Itinerary[]> {
    return this._itineraries.asObservable();
  }

  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  get getItinerariesValue(): Itinerary[] {
    return this._itineraries.value;
  }

  nextPagination(pagination: Pagination): void {
    this._pagination.next(pagination);
  }

  nextItineraries(itineraries: Itinerary[]): void {
    this._itineraries.next(itineraries);
  }
}
