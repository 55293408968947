import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { createApi } from 'unsplash-js';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  private unsplash: any;

  constructor(private _httpClient: HttpClient, ) {
    this.unsplash = createApi({
      accessKey: environment.unsplashAccessKey,
    });
  }

  uploadFile(formData: FormData): Observable<{
    data: {
      name: string;
      url: string;
    };
    errors: [];
    success: boolean;
  }> {
    return this._httpClient.post<{
      data: {
        name: string;
        url: string;
      };
      errors: [];
      success: boolean;
    }>(environment.baseUrl + "user/medical-record/file", formData);
  }

  getUnsplashSearchResult(searchTerm: string) {
    return this.unsplash.search.getPhotos({
      query: searchTerm,
      orientation: 'landscape',
      per_page: 25,
    });
  }
}
