import { Component, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { TripsService } from '../../../services';
import { Card, Code, Itinerary, Passenger, SummaryCard } from '../../../types/objects';
import { CatalogService, ModalService } from '../../services';
import { UpdatePassengerRequest } from '../../../types/request-types';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PassengerFollowingListModalComponent } from '../../../modules/admin/trip/passenger-following-list-modal/passenger-following-list-modal.component';

@Component({
  selector: 'passenger-card',
  templateUrl: './passenger-card.component.html',
  styleUrls: ['./passenger-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PassengerCardComponent implements OnDestroy {
  private _unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private matDialog: MatDialog
  ) { }


  editPassenger(): void {
  }
  
  openParentManager(): void {
    // this.matDialog.open(PassengerFollowingListModalComponent, {
    //   width: '1000px',
    //   data: {
    //     tripId: this.tripId,
    //     passengerId: this.card.data.passengerId,
    //     passengerName: this.card.data.name + ' ' + this.card.data.last_name
    //   }
    // });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}