import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Response, SingleResponse,
} from '../types/response-types';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { NotificationHistory, Trip, TripNotification } from 'app/types/objects';

@Injectable({
  providedIn: 'root'
})
export class ScheduleNotificationsService {
  constructor(
    private _httpClient: HttpClient
  ) { }

  createGeneralNotification(payload): Observable<Response<any>> {
    return this._httpClient.post<any>(environment.baseUrl + `/notifications/schedule`, payload);
  }

  getGeneralNotifications(page: number, limit: number): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.baseUrl + '/notifications/schedule/general-notifications', {
      params: {
        "page": page,
        "limit": limit
      }
    });
  }

  createNotification(payload: any): Observable<Response<any>> {
    return this._httpClient.post<any>(environment.baseUrl + `/notifications/schedule`, payload);
  }

  updateNotification(notificationId: number, payload: any): Observable<Response<any>> {
    return this._httpClient.put<any>(environment.baseUrl + `/notifications/schedule/${notificationId}`, payload);
  }

  deleteNotification(notificationId: number, tripId?: number, tripGroupId?: number): Observable<any> {
    const url = `/notifications/schedule/trips/${tripId}/${tripGroupId}/${notificationId}`;
    return this._httpClient.delete<any>(environment.baseUrl + url);
  }

  deleteGeneralNotification(notificationId: number): Observable<any> {
    return this._httpClient.delete<any>(environment.baseUrl + `/notifications/schedule/${notificationId}`);
  }

  getNotifications(tripId: number): Observable<SingleResponse<NotificationHistory>> {
    return this._httpClient.get<SingleResponse<NotificationHistory>>(environment.baseUrl + `/notifications/schedule/trips/${tripId}`);
  }

  getTrips(page: number, limit: number): Observable<Response<TripNotification>> {
    return this._httpClient.get<Response<TripNotification>>(environment.baseUrl + '/notifications/schedule', {
      params: {
        "page": page,
        "limit": limit
      }
    });
  }
}
