import uuid4 from 'uuid4'
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';


import { Itinerary, ImportItineraries, ExportActivities, DateActivity } from '../types/objects';
import { environment } from '../../environments/environment';
import { Response, SingleResponse } from '../types/response-types';
import { AuthService } from 'app/core';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { SseClient } from 'ngx-sse-client';


@Injectable()
export class ItinerariesService {
  hadChanges: boolean = false;
  // wasSaved: boolean = false;
  
  aiSubmitEvent: Subject<any> = new Subject<any>();

  _streamResponse: Subject<any> = new Subject();
  public streamResponse: Observable<any> = this._streamResponse.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private sseClient: SseClient,
    private _authService: AuthService,
  ) {
  }

  getNotificationKey(): string {
    let key = localStorage.getItem("redis_notification_key");
    if(!key){
      key = uuid4()
      localStorage.setItem("redis_notification_key", key);
    }

    return key;
  }

  getItineraries(tripId: Number): Observable<Response<Itinerary>> {
    return this._httpClient.get<Response<Itinerary>>(environment.baseUrl + `/trips/${tripId}/itineraries`, {});
  }
  
  getActivities(tripId: Number, itineraryId: Number): Observable<SingleResponse<DateActivity>>{
    return this._httpClient.get<SingleResponse<DateActivity>>(environment.baseUrl + `/trips/${tripId}/itineraries/${itineraryId}/activities`, {});
  }

  createActivity(tripId: Number, itineraryId: Number, req: any): Observable<SingleResponse<Number>>{
    return this._httpClient.post<SingleResponse<Number>>(environment.baseUrl + `/trips/${tripId}/itineraries/${itineraryId}/activities`, req);
  }

  updateActivity(tripId: Number, itineraryId: Number, activityId: Number, req: any): Observable<SingleResponse<Number>>{
    return this._httpClient.put<SingleResponse<Number>>(environment.baseUrl + `/trips/${tripId}/itineraries/${itineraryId}/activities/${activityId}`, req);
  }

  deleteActivity(tripId: Number, itineraryId: Number, activityId: Number): Observable<SingleResponse<Number>>{
    return this._httpClient.delete<SingleResponse<Number>>(environment.baseUrl + `/trips/${tripId}/itineraries/${itineraryId}/activities/${activityId}`);
  }

  generateAIActivities(tripId: number, itineraryId: number, startDate: string, endDate: string, prompt: string): Observable<SingleResponse<DateActivity>> {
    return this._httpClient.post<SingleResponse<DateActivity>>(environment.baseUrl + `/trips/${tripId}/itineraries/${itineraryId}/ai/?key=${this.getNotificationKey()}`, {
      "prompt": prompt,
      "startDate": startDate,
      "endDate": endDate
    });
  }

  runAI(tripId: Number, prompt: string): Observable<any>{
    return this._httpClient.post<any>(environment.baseUrl + `/trips/${tripId}/itineraries/ai/?key=${this.getNotificationKey()}`, {
      "prompt": prompt
    });
  }

  async stream(tripId: number) {
    const url = `${environment.baseUrl}/trips/${tripId}/itineraries/ai/stream/?key=${this.getNotificationKey()}&enterprise_id=${this._authService.enterpriseId}`;
    const requestHeaders = {
      'x-EnterpriseId': this._authService.enterpriseId,
      'Authorization': this._authService.accessToken
    };

    this.sseClient
      .stream(url, { keepAlive: true, responseType: 'event' }, { headers: requestHeaders }, 'GET')
      .pipe()
      .subscribe({
        next: (response: MessageEvent) => {
          console.log(response);
          if (!response.data) return;

          this._streamResponse.next(response);
        }
      });
  }

  tokenizer(tripId: Number, prompt: string): Observable<Response<any>>{
    return this._httpClient.post<Response<any>>(environment.baseUrl + `/trips/${tripId}/itineraries/tokenizer`, {
      "prompt": prompt
    });
  }

  save(tripId: Number, req: any): Observable<Response<Itinerary>>{
    return this._httpClient.post<Response<Itinerary>>(environment.baseUrl + `/trips/${tripId}/itineraries/save`, req);
  }

  createItinerary(tripId: Number, req: any): Observable<SingleResponse<Number>>{
    return this._httpClient.post<SingleResponse<Number>>(environment.baseUrl + `/trips/${tripId}/itineraries`, req);
  }

  updateItinerary(tripId: Number, itineraryId: Number, req: any): Observable<SingleResponse<Number>>{
    return this._httpClient.put<SingleResponse<Number>>(environment.baseUrl + `/trips/${tripId}/itineraries/${itineraryId}`, req);
  }

  createSurvey(tripId: Number, req: any){
    return this._httpClient.post<SingleResponse<Number>>(environment.baseUrl + `/trips/${tripId}/itineraries/surveys`, req);
  }

  importItineraries(tripId: number, formData: FormData): Observable<{
    data: ImportItineraries,
    errors: [],
    success: boolean
  }>{
    return this._httpClient.post<{
      data: ImportItineraries,
      errors: [],
      success: boolean
    }>(environment.baseUrl + `/trips/${tripId}/itineraries/import`, formData);
  }

  exportActivities(tripId: number, itineraryId: number): Observable<{
    data: ExportActivities,
    errors: [],
    success: boolean
  }>{
    return this._httpClient.get<{
      data: ExportActivities,
      errors: [],
      success: boolean
    }>(environment.baseUrl + `/trips/${tripId}/itineraries/${itineraryId}/export`);
  }

}
function takeUntil(_unsubscribeAll: any): import("rxjs").OperatorFunction<Event, unknown> {
  throw new Error('Function not implemented.');
}

