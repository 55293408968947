<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<div class="grid w-full" [style]="'grid-template-rows: ' + (_enterpriseService.showActivationsAlert || _enterpriseService.showActivationsLimitReachAlert ? '30px calc(100vh - 30px);' : '100vh')">
    <div *ngIf="_enterpriseService.showActivationsAlert" class="flex justify-between items-center w-full bg-amber-500 text-white font-medium px-4 py-1">
        Pocas activaciones restantes
        <button class="w-4 h-4" (click)="_enterpriseService.showActivationsAlert = false;">
            <mat-icon class="text-white text-base w-4 h-4 min-w-4 min-h-4">cancel</mat-icon>
        </button>
    </div>
    <div *ngIf="_enterpriseService.showActivationsLimitReachAlert" class="w-full bg-red-600 text-white font-medium px-4 py-1">
        Limite de activaciones alcanzado
    </div>
    <empty-layout *ngIf="layout === 'empty'"></empty-layout>
    
    <!-- ----------------------------------------------------------------------------------------------------- -->
    <!-- Layouts with horizontal navigation -->
    <!-- ----------------------------------------------------------------------------------------------------- -->
    
    <!-- Centered -->
    <centered-layout *ngIf="layout === 'centered'"></centered-layout>
    
    <!-- Enterprise -->
    <enterprise-layout *ngIf="layout === 'enterprise'"></enterprise-layout>
    
    <!-- Material -->
    <material-layout *ngIf="layout === 'material'"></material-layout>
    
    <!-- Modern -->
    <modern-layout *ngIf="layout === 'modern'"></modern-layout>
    
    <!-- ----------------------------------------------------------------------------------------------------- -->
    <!-- Layouts with vertical navigation -->
    <!-- ----------------------------------------------------------------------------------------------------- -->
    
    <!-- Basic -->
    <basic-layout *ngIf="layout === 'basic'"></basic-layout>
    
    <!-- Classic -->
    <classic-layout *ngIf="layout === 'classic'"></classic-layout>
    
    <!-- Classy -->
    <classy-layout *ngIf="layout === 'classy'"></classy-layout>
    
    <!-- Compact -->
    <compact-layout *ngIf="layout === 'compact'"></compact-layout>
    
    <!-- Dense -->
    <dense-layout *ngIf="layout === 'dense'"></dense-layout>
    
    <!-- Futuristic -->
    <futuristic-layout *ngIf="layout === 'futuristic'"></futuristic-layout>
    
    <!-- Thin -->
    <thin-layout *ngIf="layout === 'thin'"></thin-layout>
</div>
