import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GeneralResponse, SingleResponse, Response } from '../types/response-types';
import { DashboardLastActivities, DashboardTrip } from 'app/types/objects';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  
  constructor(
    private _httpClient: HttpClient, 
  ) { }

  fetchGeneralData(): Observable<SingleResponse<GeneralResponse>> {
    return this._httpClient.get<SingleResponse<GeneralResponse>>(environment.baseUrl + '/dashboard/general');
  }

  fetchRecentPhotos(): Observable<Response<string>> {
    return this._httpClient.get<Response<string>>(environment.baseUrl + '/dashboard/photos');
  }

  fetchLastActivities(): Observable<Response<DashboardLastActivities>> {
    return this._httpClient.get<Response<DashboardLastActivities>>(environment.baseUrl + '/dashboard/last-activities');
  }

  fetchTrips(): Observable<Response<DashboardTrip>> {
    return this._httpClient.get<Response<DashboardTrip>>(environment.baseUrl + '/dashboard/trips');
  }
}
