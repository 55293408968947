import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { Provider } from '../types/objects';
import { LIST_PAGE_SIZE_DEFAULT } from '../shared';
import { environment } from '../../environments/environment';
import {
  CreateProviderResponse,
  Response,
  UpdateProviderResponse,
  ReadProviderResponse
} from '../types/response-types';

import { ProvidersCommunicationService } from './providers-communication.service';

@Injectable()
export class ProvidersService {
  constructor(
    private _httpClient: HttpClient,
    private _providersCommunication: ProvidersCommunicationService
  ) {
  }

  updateProvider(id: number, provider: Provider): Observable<UpdateProviderResponse> {
    delete provider.providerId;
    return this._providersCommunication.providers$.pipe(
      take(1),
      switchMap(() => this._httpClient.put<UpdateProviderResponse>(environment.baseUrl + `/providers/${id}`, provider)
        .pipe(
          tap((response: UpdateProviderResponse) => {
            this.getProviderById(id).subscribe((updatedProviderById) => {
              if (response.success) {
                const providers = this._providersCommunication.getProvidersValue;
                const updatedProviderIndex = providers.findIndex(p => p.providerId === id);

                if (updatedProviderIndex >= 0) {
                  const previousProviders = providers.slice(0, updatedProviderIndex);
                  const posteriorProviders =
                    providers.length > updatedProviderIndex
                      ? providers.slice(updatedProviderIndex + 1, providers.length)
                      : [];

                  const updatedProvider = providers[updatedProviderIndex];
                  updatedProvider.country = updatedProviderById.country;
                  updatedProvider.document = updatedProviderById.document;
                  updatedProvider.name = updatedProviderById.name;
                  updatedProvider.type = updatedProviderById.type;

                  this._providersCommunication.nextProviders([...previousProviders, updatedProvider, ...posteriorProviders]);
                }
              }

              return response;
            });
          })
        ))
    );
  }

  deleteProvider(providerId): Observable<boolean> {
    return this._providersCommunication.providers$.pipe(
      take(1),
      switchMap(providers => this._httpClient.delete<any>(environment.baseUrl + `/providers/${providerId}`).pipe(
        tap((result) => {
          if (result.success) {
            this.getProviders().subscribe(() => {
            });
          }

          return result.success;
        })
      ))
    );
  }

  getProviders(
    page: number = 1,
    size: number = LIST_PAGE_SIZE_DEFAULT,
    sort = 'title',
    order: 'asc' | 'desc' | '' = 'asc',
    filter: string = ''
  ): Observable<Response<Provider>> {

    return this._httpClient
      .get<Response<Provider>>(environment.baseUrl + '/providers/', {
        params: {
          page: '' + page,
          limit: '' + size,
          sort,
          order,
          "filter.name": filter
        }
      })
  }

  getProviderById(id: number): Observable<Provider> {
    return this._httpClient.get<ReadProviderResponse>(environment.baseUrl + `/providers/${id}`).pipe(
      map((providerResponse: ReadProviderResponse) => {
        this._providersCommunication.nextProvider(providerResponse.data);
        return providerResponse.data;
      })
    );
  }

  newProvider(provider: Provider): void {
    const providers = this._providersCommunication.getProvidersValue;

    if (providers.find(item => item.providerId === null)) {
      providers.shift();
    }

    this._providersCommunication.nextProviders([provider, ...providers]);
  }

  cancelNewProvider(selectedProvider: Provider): void {
    this._providersCommunication.providers$.pipe(
      take(1),
      map((providers: Provider[]) => {
        if (providers.find(item => item === selectedProvider)) {
          providers.shift();
        } else {
          return false;
        }

        this._providersCommunication.nextProviders(providers);
        return true;
      })
    );
  }

  createProvider(provider: Provider): Observable<CreateProviderResponse> {
    delete provider.providerId;
    return this._providersCommunication.providers$.pipe(
      take(1),
      switchMap(providers => this._httpClient.post<CreateProviderResponse>(environment.baseUrl + '/providers', provider).pipe(
        tap(response => {
          this.getProviderById(response.providerId).subscribe((createdProviderById: Provider) => {
            if (response.success) {
              const resProviders = this._providersCommunication.getProvidersValue;
              providers.shift();
              this._providersCommunication.nextProviders([createdProviderById, ...resProviders]);
            }

            return response;
          });
        })
      ))
    );
  }
}
