<mat-nav-list>
    <div class="mb-4">
        <span class="text-secondary text-sm">Selecciona desde donde agregar la imagen...</span>
    </div>
    <button mat-list-item (click)="handleOptionSelect('device')">
      <div matLine class="label">Seleccionar desde mi equipo</div>
      <span matLine class="text-secondary">Selecciona y sube una imagen desde tu dispositivo.</span>
    </button>
  
    <button mat-list-item (click)="handleOptionSelect('unsplash')">
      <span matLine class="label">Seleccionar desde Unsplash</span>
      <span matLine class="text-secondary">Busca en internet y selecciona imágenes de fotógrafos profesionales.</span>
    </button>
  
    <button mat-list-item (click)="handleOptionSelect('url')">
      <span matLine class="label">Introducir una URL</span>
      <span matLine class="text-secondary">Usa la URL de una imagen especifica.</span>
    </button>
  </mat-nav-list>