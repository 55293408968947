import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FilesService } from 'app/services';
import { Basic } from 'unsplash-js/dist/methods/photos/types';

export interface UnsplashPhoto extends Basic {};

@Component({
  selector: 'app-unsplash-modal',
  templateUrl: './unsplash-modal.component.html',
  styleUrls: ['./unsplash-modal.component.scss'],
})
export class UnsplashModalComponent implements OnInit {

  unsplashSearchResult: UnsplashPhoto[] = [];
  unsplashSelectedImages: UnsplashPhoto[] = [];
  unsplashSelectedImagesIds: Set<string> = new Set()
  selectedImageIndex: number = 0;

  constructor(
    private _fileService: FilesService,
    public matDialogRef: MatDialogRef<UnsplashModalComponent>
  ) { }

  ngOnInit(): void {}

  search(value: string) {
    this._fileService.getUnsplashSearchResult(value)
      .then((response) => {
        console.log(response)
        this.unsplashSearchResult = response.response.results;
      });
  }

  selectUnsplashImage(image: UnsplashPhoto) {
    console.log(image);
    if (this.unsplashSelectedImagesIds.has(image.id)) return;
    
    this.selectedImageIndex = null;
    this.unsplashSelectedImages.push(image);
    this.unsplashSelectedImagesIds.add(image.id);
    this.selectedImageIndex = this.unsplashSelectedImages.length - 1;
  }

  removeImage() {
    this.unsplashSelectedImagesIds.delete(this.unsplashSelectedImages[this.selectedImageIndex].id);
    this.unsplashSelectedImages.splice(this.selectedImageIndex, 1);
    this.selectedImageIndex = this.selectedImageIndex === 0 ? 0 : this.selectedImageIndex - 1;
  }

  addImages() {
    const imageUrls = this.unsplashSelectedImages.map((image) => image.urls.regular);
    console.log(imageUrls);
    this.matDialogRef.close(imageUrls);
  }
}
