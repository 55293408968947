import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SummaryCard } from '../../../types/objects';

@Component({
  selector: 'summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent implements OnInit, OnDestroy {
  @Input() iconType: string;
  @Input() summaryCard: SummaryCard;
  @Output() selection = new EventEmitter<SummaryCard>();
  maxPassengersExceeded: boolean = false;

  // Private
  private _unsubscribeAll: Subject<void>;

  constructor() {
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    if (Number(this.summaryCard?.complementaryTitle) > this.summaryCard?.maxPassengers) {
      this.maxPassengersExceeded = true;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onSelection(): void {
    this.selection.emit(this.summaryCard);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
}
