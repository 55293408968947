import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SocialService } from '../../../services/social.service';
import { MediaService } from '../../../services/media.service'
import { Group } from '../../../types/objects';
import { Subject } from 'rxjs';
import { SocialPostFormGroup } from '../../../modules/admin/formGroups/index';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { GroupsService, ServicesService, TripsService } from 'app/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Response } from 'app/types/response-types';
import moment from 'moment';

interface SelectedImages {
  sanitizedUrl: any,
  originalFile: File,
  isUploaded: boolean,
};

interface UploadedImages {
  url: string,
  tripImageId: number
}

@Component({
  selector: 'app-create-post-modal',
  templateUrl: './create-post-modal.component.html',
  styleUrls: ['./create-post-modal.component.scss']
})
export class CreatePostModalComponent implements OnInit, AfterViewInit {
  @Output() getPosts: EventEmitter<null> = new EventEmitter();
  @Output() openPost: EventEmitter<null> = new EventEmitter();

  @ViewChild('fileInput') fileInput!: ElementRef;

  unsubscribeSubject: Subject<void> = new Subject<void>();

  groups: Group[] = [];
  isLoading: boolean = false;
  isPostUploaded: boolean = false;
  lastUploadedImageIndex: number = 0;
  moment = moment;
  selectedGroups: number[] = [];
  selectedImages: SelectedImages[] = [];
  socialPostForm: FormGroup;
  totalImages: number = 0;
  tripId: number;
  uploadedImages: UploadedImages[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileService: ServicesService,
    private _formBuilder: FormBuilder,
    private _mediaService: MediaService,
    private _groupService: GroupsService,
    private _socialService: SocialService,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public matDialogRef: MatDialogRef<CreatePostModalComponent>,
  ) {
    this.tripId = data.tripId;
    this.selectedGroups.push(data.tripGroupId);
  }

  ngOnInit(): void {
    this.socialPostForm = SocialPostFormGroup(this._formBuilder);
    this.getGroups();
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  get uploadedImagesLabel(): string {
    return `${this.uploadedImages.length}/${this.totalImages} ${this.totalImages === 1 ? 'imagen subida' : 'imágenes subidas'}`;
  }

  selectFiles(event: Event): void {
    const inputElement = event.target as HTMLInputElement
    const selectedFiles = Array.from(inputElement.files);

    for (let file of selectedFiles) {
      const fileUrl = URL.createObjectURL(file);
      this.selectedImages.push({
        sanitizedUrl: this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl),
        originalFile: file,
        isUploaded: false,
      });
    }
    this.totalImages = this.selectedImages.length;

    this.changeDetector.detectChanges();
  }

  removeImage(index: number) {
    this.selectedImages.splice(index, 1);
    this.totalImages = this.selectedImages.length;
  }

  async createPost() {
    if (this.selectedImages.length) {
      this.isLoading = true;

      const tags = this.socialPostForm.get("tags").value;
      const description = this.socialPostForm.get("description").value;

      let tripGroupIds = "-1";
      if (this.selectedGroups.length && !this.selectedGroups.includes(-1)) {
        tripGroupIds = this.selectedGroups.join(",");
      }

      this._socialService.createPost(tags, description, this.tripId, tripGroupIds)
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((response: Response<{ userPostsIds: number[] }>) => {
          if (response.success && response.data["userPostsIds"].length) {
            this.uploadImagesToPost(response.data["userPostsIds"]);
          } else if (response.success && !response.data["userPostsIds"].length) {
            this.isLoading = false;
          }
        }
        )
    } else {
      this.isLoading = true;
    }
  }

  async uploadImagesToPost(userPostsIds: number[]) {
    const selectedImageIndex = this.selectedImages.findIndex(image => !image.isUploaded);
    const selectedImage = this.selectedImages[selectedImageIndex];
    if (selectedImage) {
      let tripGroupIds = "-1";
      if (this.selectedGroups.length && !this.selectedGroups.includes(-1)) {
        tripGroupIds = this.selectedGroups.join(",");
      }

      this._mediaService.compressImage(selectedImage.originalFile)
        .subscribe((compressedImage: File) => {
          this._socialService.uploadImageToPost(this.tripId, tripGroupIds, userPostsIds, compressedImage)
            .pipe(
              takeUntil(this.unsubscribeSubject),
            )
            .subscribe((event) => {
              const isDownloadFinished = event["type"] === HttpEventType.Response;
              if (isDownloadFinished) {
                if (event.body["success"]) {
                  this.uploadedImages.push(event.body["data"]);
                  this.lastUploadedImageIndex = this.uploadedImages.length;
                  this.selectedImages[selectedImageIndex].isUploaded = true;
                }
                this.uploadImagesToPost(userPostsIds);
              }
            })
        })
    } else {
      this.isLoading = false;
      this.isPostUploaded = true;
      this.getPosts.emit();
    }
  }

  getGroups(): void {
    this._groupService.getGroups(this.tripId)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((response: Response<Group>) => {
        if (response.success) {
          this.groups = response.data;
          this.selectedGroups = this.groups.map((group: Group) => group.tripGroupId);
          this.changeGroup([this.data.tripGroupId]);
          this.changeDetector.detectChanges();
        }
      })
  }

  changeGroup(tripGroupIds: number[]): void {
    if (this.selectedGroups.includes(-1) && !tripGroupIds.includes(-1)) {
      this.selectedGroups = [];
    } else if ((this.selectedGroups.includes(-1) && tripGroupIds.includes(-1)) || (!this.selectedGroups.includes(-1) && !tripGroupIds.includes(-1))) {
      this.selectedGroups = this.groups.filter(group => tripGroupIds.includes(group.tripGroupId)).map(group => group.tripGroupId);
    } else {
      this.selectedGroups = this.groups.map(group => group.tripGroupId);
      this.selectedGroups.push(-1);
    }
    this.socialPostForm.get('groups').setValue(this.selectedGroups);
  }

  formatHashtags(event: KeyboardEvent): void {
    if (event.code != "Backspace") {
      const inputElement = event.target as HTMLInputElement;
      const hashtags = inputElement.value.replace(/[^\w\s]/gi, '');
      inputElement.value = hashtags.split(" ").map(word => `#${word}`).join(" ");
    }
  }

  closeModal(): void {
    this.matDialogRef.close(this.isPostUploaded);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
