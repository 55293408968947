import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Passenger } from 'app/types/objects';

@Component({
  selector: 'confirm-add-passenger',
  templateUrl: './confirm-add-passenger.component.html',
  styleUrls: ['./confirm-add-passenger.component.scss']
})
export class ConfirmAddPassengerComponent implements OnInit {
  title = 'Ya existe un pasajero con el mismo nombre';
  message = '¿Desea agregarlo igualmente?';
  passengerToAdd: Passenger;
  passengerToReplace: Passenger;
  tripId: number;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ConfirmAddPassengerComponent>,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.passengerToAdd = this.data.passengerToAdd;
    this.passengerToReplace = this.data.passengerToReplace;
    this.tripId = this.data.tripId;
  }

  showCopyField(name: string): void {
  }

  cancel(): void {
    this.matDialogRef.close(false);
  }

  accept(): void {
    this.matDialogRef.close(true);
  }

  replacePassenger(passengerId): void {
    this.matDialogRef.close({
      passengerId: passengerId,
      replace: true
    });
  }

}
