import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {
  GetPassengerResponse,
  Response,
  SingleResponse
} from '../types/response-types';
import { Passenger } from 'app/types/objects';
import { AddPassengerRequest, UpdatePassengerRequest } from 'app/types/request-types';
import { Document } from 'app/types/objects/document.type';

@Injectable({
  providedIn: 'root'
})
export class PassengersService {
  constructor(private _httpClient: HttpClient) {
  }

  searchPassenger(tripId: number, emailPhoneData: any): Observable<SingleResponse<Passenger>> {
    return this._httpClient.get<SingleResponse<Passenger>>(environment.baseUrl + `/trips/${tripId}/passengers/search`, {
      params: emailPhoneData
    })
  }

  addPassenger(tripId: number, tripGroupId: number, addPassengerRequest: any): Observable<SingleResponse<any>> {
    return this._httpClient.post<SingleResponse<any>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}/passengers`, addPassengerRequest);
  }

  updatePassenger(tripId: number, tripGroupId: number, passengerId: number): Observable<SingleResponse<any>> {
    return this._httpClient.put<SingleResponse<any>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}/passengers/${passengerId}`, {});
  }

  deletePassenger(tripId: number, tripGroupId: number, passengerId: number): Observable<SingleResponse<any>> {
    return this._httpClient.delete<SingleResponse<any>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}passengers/${passengerId}`);
  }

  sendNotification(passengerId: number, tripId: number): Observable<GetPassengerResponse> {
    return this._httpClient.post<GetPassengerResponse>(environment.baseUrl + `/trips/${tripId}/passengers/${passengerId}/notification`,
      {
        'passengerId': passengerId,
        'tripId': Number(tripId)
      });
  }

  // Documents

  getPassengerDocuments(tripId: number, groupId: number, passengerId: number): Observable<Document[]> {
    return this._httpClient.get<Document[]>(`${environment.baseUrl}/trips/${tripId}/groups/${groupId}/passengers/${passengerId}/documents`);
  }

  uploadDocument(formData: FormData, tripId: number, groupId: number, passengerId: number): Observable<Document> {
    return this._httpClient.post<Document>(`${environment.baseUrl}/trips/${tripId}/groups/${groupId}/passengers/${passengerId}/documents`, formData);
  }

  updateDocument(tripId: number, groupId: number, passengerId: number, documentId: number, documentName: string): Observable<null> {
    const body = JSON.stringify({ name: documentName });
    return this._httpClient.put<null>(`${environment.baseUrl}/trips/${tripId}/groups/${groupId}/passengers/${passengerId}/documents/${documentId}`, body);
  }

  deleteDocument(tripId: number, groupId: number, passengerId: number, documentId: number): Observable<null> {
    return this._httpClient.delete<null>(`${environment.baseUrl}/trips/${tripId}/groups/${groupId}/passengers/${passengerId}/documents/${documentId}`);
  }
}
