import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { mergeMap, take, map } from "rxjs/operators";
import { environment } from '../../environments/environment';
import {
  OrderReportResponse,
  Response,
  SingleResponse
} from '../types/response-types';
import { Product, PaymentProcessor, OrderReport, TotalSale } from '../types/objects';
import { LIST_PAGE_SIZE_DEFAULT } from 'app/shared/constants';
import { AlertsService } from './alerts.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

export interface OrderHistory {date: string, user: string, status: string}

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  wizardIsLoading = new Subject<boolean>();
  
  private productsSubject = new BehaviorSubject<Product[]>([]);
  private closeDialogSubject = new Subject<boolean>();
  private isLoadingSubject = new Subject<boolean>();

  moment = moment;

  constructor(
    private _httpClient: HttpClient,
    private alertService: AlertsService,
    private translateService: TranslateService,
  ) { }

  get closeDialogObservable() {
    return this.closeDialogSubject.asObservable();
  }

  get loadingObservable() {
    return this.isLoadingSubject.asObservable();
  }

  get productsObservable() {
    return this.productsSubject.asObservable();
  }

  getProducts(tripId: number) {
    this.isLoadingSubject.next(true);
    this._httpClient
      .get<Product[]>(environment.storeUrl + `/trips/${tripId}/products`)
      .subscribe({
        next: (products) => this.productsSubject.next(products),
        error: (error: HttpErrorResponse) => {
          if (error.status !== 429) {
            this.alertService.showSnackbarAlert(this.translateService.instant('STORE.ERRORS.GET_PRODUCTS'));
          }
        },
        complete: () => this.isLoadingSubject.next(false)
      })
  }

  createProduct(tripId: number, productData: Product) {
    this.isLoadingSubject.next(true);
    this._httpClient
      .post<{ productId: number }>(environment.storeUrl + `/trips/${tripId}/products`, productData)
      .pipe(
        mergeMap((newProduct) => this.productsObservable.pipe(
          take(1),
          map((currentProducts) => ([
            ...currentProducts,
            {
              ...productData,
              productId: newProduct.productId
            }
          ])
          )
        ))
      )
      .subscribe({
        next: (products) => {
          this.productsSubject.next(products);
          this.closeDialogSubject.next(true);
          this.alertService.showSnackbarAlert('Producto creado exitosamente');
        },
        error: (error) => console.log(error), //TODO: Manejar errores
        complete: () => this.isLoadingSubject.next(false)
      })
  }

  updateProduct(tripId: number, productId: number, productData: Product) {
    this.isLoadingSubject.next(true);
    this._httpClient
      .put<null>(`${environment.storeUrl}/trips/${tripId}/products/${productId}`, productData)
      .pipe(
        mergeMap(() => this.productsObservable.pipe(
          take(1),
          map((currentProducts) => {
            const updatedProductIndex = currentProducts.findIndex((product) => product.productId === productId);
            const currentProductsCopy = [...currentProducts];
            currentProductsCopy.splice(updatedProductIndex, 1, productData);
            return currentProductsCopy;
          })
        ))
      )
      .subscribe({
        next: (updatedProductsList) => {
          this.productsSubject.next(updatedProductsList);
          this.closeDialogSubject.next(true);
          this.alertService.showSnackbarAlert('Producto editado exitosamente');
        },
        error: (error) => console.log(error), //TODO: Manejar errores
        complete: () => this.isLoadingSubject.next(false)
      })
  }

  deleteProduct(tripId: number, productId: number) {
    this.isLoadingSubject.next(true);
    this._httpClient
      .delete<SingleResponse<string>>(environment.storeUrl + `/trips/${tripId}/products/${productId}`)
      .pipe(
        mergeMap(() => this.productsObservable.pipe(
          take(1),
          map((currentProducts) => {
            const productToDeleteIndex = currentProducts.findIndex((product) => product.productId === productId);
            const currentProductsCopy = [...currentProducts];
            currentProductsCopy.splice(productToDeleteIndex, 1);
            return currentProductsCopy;
          })
        ))
      )
      .subscribe({
        next: (updatedProductsList) => {
          this.productsSubject.next(updatedProductsList);
          this.closeDialogSubject.next(true);
          this.alertService.showSnackbarAlert('Producto eliminado exitosamente');
        },
        error: (error) => console.log(error), //TODO: Manejar errores
        complete: () => this.isLoadingSubject.next(false)
      })
  }

  getOrders(
    page: number = 1,
    size: number = LIST_PAGE_SIZE_DEFAULT
  ): Observable<OrderReportResponse> {

    return this._httpClient
      .get<OrderReportResponse>(environment.storeUrl + '/report', {
        params: {
          page: '' + page,
          page_size: '' + size
        }
      })
  }

  getDetails(
    tripId: number,
    orderId: string,
  ): Observable<Response<OrderReport>> {

    return this._httpClient
      .get<Response<OrderReport>>(environment.storeUrl + `/trips/${tripId}/orders/${orderId}/details`, {})
  }

  getHistory(
    orderId: number,
  ): Observable<OrderHistory[]> {

    return this._httpClient
      .get<OrderHistory[]>(environment.storeUrl + `/orders/${orderId}/historic`)
  }

  changeOrderStatus(
    orderId: number,
    status: 'RESERVED' | 'PAID' | 'DELIVERED' | 'DECLINED'
  ): Observable<any> {
    return this._httpClient.put(`${environment.storeUrl}/orders/${orderId}`, {status});
  }

  getReport(
    page: number = 1,
    page_size: number = LIST_PAGE_SIZE_DEFAULT,
    args
  ) {
    let { startDate, endDate, product, status, passenger, paymentType, trip, tripGroup, coordinator, tripEndDate, hotel } = args;

    status = Array.isArray(status) ? status.join(",") : "";
    return this._httpClient
      .get<{data: OrderReport[], totalRecords: number}>(`${environment.storeUrl}/report`, {
        params: {
          page,
          page_size,
          startDate: startDate && this.moment(startDate).format('YYYY-MM-DD'),
          endDate: endDate && this.moment(endDate).format('YYYY-MM-DD'),
          product,
          status: status,
          passenger,
          paymentType,
          trip,
          hotel,
          tripGroup,
          coordinator,
          tripEndDate: tripEndDate && this.moment(tripEndDate).format('YYYY-MM-DD'),
        }
      })
  }

  getTotalSalesByCurrency(
    args
  ) {
    let { startDate, endDate, product, status, passenger, paymentType, trip, tripGroup, coordinator, tripEndDate, hotel } = args;

    status = Array.isArray(status) ? status.join(",") : "";
    return this._httpClient
      .get<TotalSale>(`${environment.storeUrl}/report/total-sales`, {
        params: {
          startDate: startDate && this.moment(startDate).format('YYYY-MM-DD'),
          endDate: endDate && this.moment(endDate).format('YYYY-MM-DD'),
          product,
          status: status,
          passenger,
          paymentType,
          trip,
          hotel,
          tripGroup,
          coordinator,
          tripEndDate: tripEndDate && this.moment(tripEndDate).format('YYYY-MM-DD'),
        }
      })
  }

  exportMetrics(
    page: number = 1,
    page_size: number = LIST_PAGE_SIZE_DEFAULT,
    fileType: "excel" | "pdf",
    filters
  ) {

    let { startDate, endDate, product, status, passenger, paymentType, trip, tripGroup, coordinator, tripEndDate, hotel } = filters;
    status = Array.isArray(status) ? status.join(",") : "";

    return this._httpClient
      .get(`${environment.storeUrl}/report/${fileType}`, {
        params: {
          page,
          page_size,
          startDate: startDate && this.moment(startDate).format('YYYY-MM-DD'),
          endDate: endDate && this.moment(endDate).format('YYYY-MM-DD'),
          product,
          status: status,
          passenger,
          paymentType,
          trip,
          hotel,
          tripGroup,
          coordinator,
          tripEndDate: tripEndDate && this.moment(tripEndDate).format('YYYY-MM-DD'),
        }
      })
  }

  getPaymentProcessors(): Observable<PaymentProcessor[]> {
    return this._httpClient.get<PaymentProcessor[]>(`${environment.storeUrl}/payment-processors`)
  }

}
