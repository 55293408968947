import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  SelectProviderDialogComponent,
  ConfirmDialogComponent,
  ShowTripCodeDialogComponent
} from '../dialogs';
import { ProvidersService, ServicesService } from '../../services';
import {
  Code,
  Itinerary,
  Passenger,
  SummaryCard
} from '../../types/objects';
import { MODAL_DIALOG_LIST_PAGE_SIZE_DEFAULT } from '../constants';
import { Observable } from 'rxjs';

@Injectable()
export class ModalService {
  constructor(
    private _matDialog: MatDialog,
    private _providersService: ProvidersService,
    private _servicesService: ServicesService
  ) {
  }

  confirmDialog(title: string, message: string, acceptAction: () => void, cancelAction: () => void = () => {
  }): void {
    const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-dialog-dialog',
      height: '100px',
      width: '200px',
      data: {
        title: title,
        message: message
      }
    });

    dialogRef.afterClosed().subscribe(isAccepted => {
      if (isAccepted) {
        acceptAction();
      } else {
        cancelAction();
      }
    });
  }



  tripCode(code, cancelAction: () => void = () => { }): void {
    const dialogRef = this._matDialog.open(ShowTripCodeDialogComponent, {
      panelClass: 'trip-code-dialog-dialog',
      height: '500px',
      width: '600px',
      data: {
        code: code
      }
    });

    dialogRef.afterClosed().subscribe(isAccepted => {
      cancelAction();
    });
  }

  selectProvider(confirmAction: (id: any) => void = id => {
  },
    cancelAction: () => void = () => {
    }): void {
    this._providersService.getProviders(1, MODAL_DIALOG_LIST_PAGE_SIZE_DEFAULT).subscribe(() => {
      const dialogRef = this._matDialog.open(SelectProviderDialogComponent, {
        panelClass: ['modal-container', 'select-provider-dialog'],
        height: '750px',
        width: '1000px',
        data: {}
      });

      dialogRef.afterClosed().subscribe(selectedProviderId => {
        if (selectedProviderId) {
          confirmAction(selectedProviderId);
        } else {
          cancelAction();
        }
      });
    });
  }
}
