<!-- Navigation -->
<treo-vertical-navigation class="bg-indigo-800 theme-dark"
                          [appearance]="'futuristic'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.futuristic"
                          [opened]="!isScreenSmall">

    <div treoVerticalNavigationHeader>
        <div class="logo">
            <img src="assets/images/logo/logo-text-on-dark.svg">
        </div>
    </div>

    <div treoVerticalNavigationFooter>
        <div class="navigation-footer-menu">

            <button [matMenuTriggerFor]="menu">
                <mat-icon [svgIcon]="'heroicons_outline:briefcase'"></mat-icon>
                <span class="line-clamp-1 text-left">{{selectedEnterprise ? selectedEnterprise.name : ''}}</span>
                <mat-icon class="ml-auto -mr-1 icon-size-20"
                          [svgIcon]="'heroicons_outline:selector'"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" (keydown)="$event.stopPropagation()">
                <!-- <mat-form-field class="treo-mat-dense treo-mat-no-subscript w-full px-4 mb-2">
                    <mat-icon matPrefix [svgIcon]="'search'"></mat-icon>
                    <input matInput [formControl]="" [autocomplete]="'off'"
                        placeholder="{{ 'PLACEHOLDERS.SEARCH_PROVIDERS' | translate }}" (click)="$event.stopPropagation()">
                </mat-form-field> -->
                <button mat-menu-item *ngFor="let enterprise of enterprises" (click)="selectEnterprise(enterprise)">
                    <mat-icon>business</mat-icon>
                    <span class="line-clamp-1 text-left">{{enterprise.name}}</span>
                </button>
            </mat-menu>

            <a routerLink="/enterprise" class="treo-vertical-navigation-item" routerLinkActive="treo-vertical-navigation-item-active">
                <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
                <span>{{ "MENU.ENTERPRISE_SETTINGS" | translate }}</span>
            </a>

            <!-- <a href="https://web.whatsapp.com/send?phone=59898111773&text=" target="_blank" class="treo-vertical-navigation-item wpp">
                <img src="/assets/images/logo/wpp-logo.png">
                <span>{{ "MENU.SUPPORT" | translate }}</span>
            </a> -->

            <button *ngIf="!enterpriseService.isFeatureDisabled(6)" (click)="openSofiaModal()" class="treo-vertical-navigation-item pr-2">
                <mat-icon>face_retouching_natural</mat-icon>
                <span>Pedir ideas a Sofía</span>
                <div class="ml-auto">
                    <chip color="green" tooltip="Inteligencia Artificial" icon="psychology"></chip>
                </div>
            </button>
            
            <a href="https://clientes.viaxlab.com/" target="_blank" class="treo-vertical-navigation-item">
                <mat-icon>help</mat-icon>
                <span>{{ "MENU.HELP" | translate }}</span>
                <mat-icon class="ml-auto mr-0 icon-size-20">open_in_new</mat-icon>
            </a>

            <app-enterprise-activations></app-enterprise-activations>
        </div>
        <div class="user">
            <span class="text-secondary text-xs text-indigo-300">
                Versión {{ version }}
            </span>
            <div class="flex gap-2">
                <app-user-notifications></app-user-notifications>
                <user [user]="user"></user>
            </div>
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>

        <!-- Theme Toggle -->
        <!-- <theme-toggle></theme-toggle>-->

        <!-- Search -->
        <!--<search [appearance]="'bar'"></search>-->

        <!-- Shortcuts -->
        <!--<shortcuts [shortcuts]="data.shortcuts"></shortcuts>-->

        <!-- Messages -->
        <!--<messages [messages]="data.messages"></messages>-->

        <!-- Notifications -->
        <!--<notifications [notifications]="data.notifications"></notifications>-->

    </div>

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <!--<div class="footer">-->
    <!--    Treo &copy; {{currentYear}}-->
    <!--</div>-->

</div>
