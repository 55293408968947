<div class="progress-bar-container relative" [class._yellow]="_enterpriseService.enterprisePlan.value?.maxPaxActivation - 20 <= _enterpriseService.enterprisePlan.value?.currentPaxActivation" [class._red]="_enterpriseService.enterprisePlan.value?.maxPaxActivation - 10 <= _enterpriseService.enterprisePlan.value?.currentPaxActivation">
                
    <div class="progress-bar">
        <div #progress class="progress"></div>
    </div>
    <span class="counter">{{ _enterpriseService.enterprisePlan.value?.currentPaxActivation }}/{{ _enterpriseService.enterprisePlan.value?.maxPaxActivation }}</span>
    <div class="mat-menu-panel absolute bg-white p-4 min-w-65" id="activations-info">
        <div class="grid gap-2">
            <div class="label">Estado actual de tu plan</div>
            <mat-divider></mat-divider>
            <div class="flex justify-between w-full text-sm">
                <span class="text-gray-500 font-normal">Activaciones consumidas</span>
                <span class="text-secondary font-semibold" [ngClass]="{'counter font-bold': _enterpriseService.enterprisePlan.value?.currentPaxActivation >= _enterpriseService.enterprisePlan.value?.maxPaxActivation}">
                    {{ _enterpriseService.enterprisePlan.value?.currentPaxActivation }}
                </span>
            </div>
            <div class="flex justify-between w-full text-sm">
                <span class="text-gray-500 font-normal">Activaciones restantes</span>
                <span class="text-secondary font-semibold">{{ _enterpriseService.enterprisePlan.value?.maxPaxActivation - _enterpriseService.enterprisePlan.value?.currentPaxActivation }}</span>
            </div>
            <div class="flex justify-between w-full text-sm">
                <span class="text-gray-500 font-normal">Limite actual del plan</span>
                <span class="text-secondary font-semibold">{{ _enterpriseService.enterprisePlan.value?.maxPaxActivation }}</span>
            </div>
            <div class="flex justify-between w-full text-sm">
                <span class="text-gray-500 font-normal">Cotizaciones restantes</span>
                <span class="text-secondary font-semibold">{{ _enterpriseService.enterprisePlan.value?.maxProposalsCreated - _enterpriseService.enterprisePlan.value?.currentProposalsCreated }}</span>
            </div>
            <div class="flex justify-between w-full text-sm">
                <span class="text-gray-500 font-normal">Límite de cotizaciones</span>
                <span class="text-secondary font-semibold">{{ _enterpriseService.enterprisePlan.value?.maxProposalsCreated }}</span>
            </div>
            <div class="flex justify-between w-full text-sm">
                <span class="text-gray-500 font-normal">Comienzo del plan</span>
                <span class="text-secondary font-semibold">{{ _enterpriseService.enterprisePlan.value?.planDates?.startDate | date : 'dd/MM/YYYY' }}</span>
            </div>
            <div class="flex justify-between w-full text-sm">
                <span class="text-gray-500 font-normal">Renovación del plan</span>
                <span class="text-secondary font-semibold">{{ _enterpriseService.enterprisePlan.value?.planDates?.renovationDate | date : 'dd/MM/YYYY' }}</span>
            </div>
            <div></div>
            <app-alert *ngIf="showLimitWarningAlert && !showLimitReachedAlert" small="true" iconName="warning" text="Pocas activaciones restantes" color="amber"></app-alert>
            <app-alert *ngIf="showLimitReachedAlert" small="true" iconName="warning" text="Limite de activaciones superado" color="red"></app-alert>
        </div>
    </div>
</div>
