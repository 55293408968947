import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Service, Pagination } from '../types/objects';

@Injectable({
  providedIn: 'root'
})
export class ServicesCommunicationService {
  private _service: BehaviorSubject<Service | null> = new BehaviorSubject(null);
  private _services: BehaviorSubject<Service[] | null> = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  constructor() {
  }

  get services$(): Observable<Service[]> {
    return this._services.asObservable();
  }

  get service$(): Observable<Service> {
    return this._service.asObservable();
  }

  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  get getServicesValue(): Service[] {
    return this._services.value;
  }

  nextService(service: Service): void {
    this._service.next(service);
  }

  nextPagination(pagination: Pagination): void {
    this._pagination.next(pagination);
  }

  nextServices(services: Service[]): void {
    this._services.next(services);
  }
}
