import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Response } from '../types/response-types';
import { LIST_PAGE_SIZE_DEFAULT } from '../shared';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  constructor(
    private _httpClient: HttpClient
  ) { }

  getMetrics(
    page: number = 1,
    size: number = LIST_PAGE_SIZE_DEFAULT,
    sort = 'title',
    order: 'asc' | 'desc' | '' = 'asc',
    filterActivity: string,
    startDate: string, 
    endDate: string
  ): Observable<Response<any>> {
    
    return this._httpClient
      .get<Response<any>>(environment.baseUrl + '/metrics/', {
        params: {
          page: '' + page,
          limit: '' + size,
          sort,
          order,
          filterActivity,
          startDate,
          endDate
        }
      })
  }

  getDetail(
    activityIds: string,
  ): Observable<Response<any>> {
    
    return this._httpClient
      .get<Response<any>>(environment.baseUrl + '/metrics/detail', {
        params: {
          activityIds
        }
      })
  }

  exportMetrics(
    page: number = 1,
    size: number = LIST_PAGE_SIZE_DEFAULT,
    filterActivity: string,
    startDate: string, 
    endDate: string
  ): Observable<Response<any>> {
    
    return this._httpClient
      .get<Response<any>>(environment.baseUrl + '/metrics/export', {
        params: {
          page: '' + page,
          limit: '' + size,
          filterActivity,
          startDate,
          endDate
        }
      })
  }
}
