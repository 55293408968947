export enum ItineraryEventTypeEnum {
  SERVICE_TYPE_ACCOMODATION = 'Alojamiento',
  SERVICE_TYPE_TRIP = 'Viaje',
  SERVICE_TYPE_EXPERIENCE = 'Experiencia',
  SERVICE_TYPE_WALK = 'Caminata',
  SERVICE_TYPE_BOAT = 'Bote',
  SERVICE_TYPE_FOOD = 'Comida',
  SERVICE_TYPE_RECREATIONAL_ACTIVITY = 'Actividad recreativa',
  SERVICE_TYPE_TRANSFER = 'Transporte',
  SERVICE_TYPE_DANCE_CLUB = 'Discoteca',
  SERVICE_TYPE_BEACH = 'Playa',
  SERVICE_TYPE_MUSEUM = 'Museo',
}
