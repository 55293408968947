export enum MessageType {
    info = 0,
    warning = 1,
    error = 2
}

export enum SearchPassengerResult {
    notSearch = 0,
    foundUser = 1,
    foundPassenger = 2,
    notFound = 3
}
