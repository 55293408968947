<div class="grid gap-4 w-fit" style="grid-template-columns: 1fr 120px;">
    <div *ngIf="imageUrls.length" class="flex gap-2 overflow-x-auto">
      <div class="relative image-container" *ngFor="let image of imageUrls">
        <img [src]="image" class="rounded-lg h-full object-cover image"/>
        <button (click)="removeImage(image)" matTooltip="Remover imagen" class="remove-image-btn">
          <mat-icon class="text-secondary text-white" svgIcon="close"></mat-icon>
        </button>
      </div>
    </div>
    <button class="grid gap-2 w-30 h-30 add-image-btn" (click)="openImageSelectorOptions()">
      <img class="w-18 m-auto" src="assets/images/imagen.png"/>
      <div class="label text-sm">Agregar imagen</div>
    </button>
    <input #uploadInput class="absolute invisible" type="file" accept=".jpg, .jpeg, .png, .heic, .heif" ng2FileSelect [uploader]="uploader" />
</div>