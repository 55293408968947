import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { NotificationSnackbarComponent } from 'app/shared/components/notification-snackbar/notification-snackbar.component';
import { UserNotification } from '../types/objects/notifications.type';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private _snackBar: MatSnackBar) { }

  showSnackbarAlert(
    message: string,
    duration: number = 5000,
    horizontalPosition: MatSnackBarHorizontalPosition = "left"
  ) {
    this._snackBar.open(message, '', {
      duration,
      horizontalPosition
    });
  }

  showNotificationSnackbar(notification: UserNotification) {
    this._snackBar.openFromComponent(NotificationSnackbarComponent, {
      duration: (1000 * 60) * 60, // Una hora
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'notification-snackbar',
      data: notification
    });
  }
}
