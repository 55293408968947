import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tripGroupName'
})
export class TripGroupNamePipe implements PipeTransform {

  transform(value: string): string {
    return value === 'unassigned' ? 'GROUPS.GROUP_BY.UNASSIGNED' : value;
  }

}
