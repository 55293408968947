import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {
  title = 'Confirmar acción';
  message = '¿Está seguro de que desea realizar esta acción?';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ConfirmDialogComponent>,
    private _formBuilder: FormBuilder
  ) {
    this.title = data?.title ? data.title : this.title;
    this.message = data?.message ? data.message : this.message;
  }

  ngOnInit(): void {
  }

  showCopyField(name: string): void {
  }

  cancel(): void {
    this.matDialogRef.close(false);
  }

  accept(): void {
    this.matDialogRef.close(true);
  }
}
