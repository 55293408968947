import { Component, ViewChild } from '@angular/core';
import { NotificationsService } from 'app/services';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent {
  constructor(public _notificationsService: NotificationsService) {}
  
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  
  closeMenu(): void {
    this.menuTrigger.closeMenu();
  }
}
