import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Code } from '../../types/objects';
import { Response } from '../../types/response-types';

@Injectable()
export class CatalogService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getProviderTypes(): Observable<Response<Code>> {
    return this._httpClient.get<Response<Code>>(environment.baseUrl + '/codes/', {
      params: {
        code: 'provider_type',
        limit: '' + 10000,
        page: '' + 1
      }
    })
  }

  getCurrencies(): Observable<Response<Code>> {
    return this._httpClient.get<Response<Code>>(environment.baseUrl + '/codes/', {
      params: {
        code: 'currency',
        limit: '' + 10000,
        page: '' + 1
      }
    })
  }

  getUserRoles(): Observable<Response<Code>> {
    return this._httpClient.get<Response<Code>>(environment.baseUrl + '/codes/', {
      params: {
        code: 'user_role',
        limit: '' + 10000,
        page: '' + 1
      }
    })
  }

  getLanguages(): Observable<Response<Code>> {
    return this._httpClient.get<Response<Code>>(environment.baseUrl + '/codes/', {
      params: {
        code: 'language',
        limit: '' + 10000,
        page: '' + 1
      }
    })
  }

}
