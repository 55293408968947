import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SseClient } from 'ngx-sse-client';
import moment from 'moment';

import { environment } from '../../environments/environment';
import { PageEvent } from '@angular/material/paginator';
import {
  Response,
  SingleResponse
} from '../types/response-types';
import {
  UserNotification,
  UserNotificationResponse,
  NotificationsListPaginationOptions,
  NotificationsResponse
} from '../types/objects/notifications.type';

import { AuthService } from 'app/core';
import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {

  // Subjects
  private _lastUserNotifications: BehaviorSubject<UserNotification[]> = new BehaviorSubject([]);
  private _unreadUserNotifications: BehaviorSubject<UserNotification[]> = new BehaviorSubject([]);
  private _userNotifications: BehaviorSubject<UserNotification[]> = new BehaviorSubject([]);
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // Observables
  public lastUserNotifications: Observable<UserNotification[]> = this._lastUserNotifications.asObservable();
  public unreadUserNotifications: Observable<UserNotification[]> = this._unreadUserNotifications.asObservable();
  public userNotifications: Observable<UserNotification[]> = this._userNotifications.asObservable();
  public notifications: Observable<UserNotification[]> = this._userNotifications.asObservable();

  // Public properties
  public error: boolean;
  public isLoading: boolean;
  public paginationOptions: NotificationsListPaginationOptions = {
    pageIndex: 0,
    pageSizeOptions: [10, 25, 50, 100],
    pageSize: 10,
    length: 0,
  };
  public showUnreadNotificationsOnly: boolean = false;
  public totalUnreadNotifications: number;
  public formattedTotalUnreadNotifications: string;
  public unreadNotificationsLabel: string;

  // Private properties
  private consoleTabIsActive: boolean = true;
  private notificationsAreLoaded: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private sseClient: SseClient,
    private _alertService: AlertsService,
    private _authService: AuthService,
  ) {
    this.getUserNotifications(null, true);
    this.handleDocumentVisibilityChange();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    document.removeAllListeners();
    clearInterval(this.notificationTimeAgoInterval);
  }

  /**
   * Ejecuta los métodos requeridos para el funcionamiento de las notificaciones. 
   * 
   * Se ejecuta cuando se hace la llamada a `getUserNotifications` por primera vez desde el constructor.
   */
  initializeConfig(): void {
    this.handleUserNotificationsChange();
    this.listenForUserNotifications();
    this.runNotificationsTimeAgoInterval();
  }

  /**
   * Maneja el cambio en las notificaciones del usuario.
   * 
   * Se suscribe a `userNotifications` y cada vez que hay un cambio, 
   * toma las últimas 5 notificaciones y las asigna a `_lastUserNotifications`.
   */
  handleUserNotificationsChange(): void {
    this.userNotifications
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((userNotifications: UserNotification[]) => {
        this._lastUserNotifications.next(userNotifications.slice(0, 5));
      });
  }

  /**
   * Realiza la petición get al endpoint de `/notifications`.
   * 
   * Si la respuesta del endpoint es exitosa se realizan las siguientes acciones:
   * 1. Ejecuta `setUserNotifications` para establecer el valor del Subject correspondiente.
   * 2. Ejecuta `setUnreadNotificationsData` para establecer las opciones de notificaciones no leídas.
   * 3. Muestra un snackbar con el mensaje dirigido al usuario en caso de ser necesario.
   * 4. En caso de que sea la primer ejecución inicializa la configuración del servicio.
   * 
   * @param {string} [successMessage] - Mensaje a mostrar en un snackbar en caso de que la respuesta haya sido exitosa.
   * @param {boolean} [initialResponse] - Determina si es la primer llamada a la API, en caso de que sí, realiza las configuraciones pertinentes.
   */
  getUserNotifications(successMessage?: (string | null), initialResponse?: (boolean | null)): void {
    const url = `${environment.baseUrl}/notifications?page=${this.paginationOptions.pageIndex + 1}&limit=${this.paginationOptions.pageSize}&unread=${this.showUnreadNotificationsOnly}`;
    this.httpClient
      .get<UserNotificationResponse>(url)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response: UserNotificationResponse) => {
          if (response.success) {
            this.setUserNotifications(response.data);
            this.setUnreadNotificationsData(response.totalUnread);

            successMessage && this._alertService.showSnackbarAlert(successMessage);
            initialResponse && this.initializeConfig();
            this.paginationOptions.length = response.total;
            this.notificationsAreLoaded = true;
            this.error = false;
          }
        },
        error: () => {
          this.error = true;
          this.isLoading = false;
        },
        complete: () => this.isLoading = false
      });
  }

  /**
   * Establece el valor del Subject `_userNotifications` o `_unreadUserNotifications`
   * dependiendo del valor de `showUnreadNotificationsOnly`.
   * 
   * Formatea las notificaciones y las almacena en el Subject correspondiente, 
   * además, actualiza `notifications` con el observable de dicho Subject.
   * 
   * @param {UserNotification[]} notifications - Array de notificaciones a ser formateado y almacenado en el Subject.
   */
  setUserNotifications(notifications: UserNotification[]): void {

    function getNotificationTimeAgo(date: string): string {
      moment.locale('es');

      const localDate = moment.utc(date, 'MM/DD/YYYY, hh:mm:ss').local().toDate();
      const duration = moment.duration(moment().diff(localDate));

      const formattedTime = duration.asMonths() > 1
        ? moment(localDate).format('DD/MM/YY')
        : moment(localDate).fromNow(true);

      return formattedTime;
    };

    const userNotifications = notifications.map((notification: UserNotification) => ({
      ...notification,
      timeAgo: getNotificationTimeAgo(notification.createdAt)
    }));

    if (this.showUnreadNotificationsOnly) {
      this._unreadUserNotifications.next(userNotifications);
      this.notifications = this._unreadUserNotifications.asObservable();
    } else {
      this._userNotifications.next(userNotifications);
      this.notifications = this._userNotifications.asObservable();
    }
  }

  /**
   * Establece el número de notificaciones sin leer, y el texto a mostrar en la lista de notificaciones.
   * 
   * @param {number} unreadNotifications - Número de notificaciones sin leer obtenido desde la API.
   */
  setUnreadNotificationsData(unreadNotifications: number): string {
    this.totalUnreadNotifications = unreadNotifications;
    this.formattedTotalUnreadNotifications = unreadNotifications > 99 ? '+99' : unreadNotifications.toString();

    if (unreadNotifications === 0 && this.showUnreadNotificationsOnly) {
      this.toggleUnreadFilter();
    }

    if (unreadNotifications === 0) return this.unreadNotificationsLabel = '¡Estas al día con las notificaciones!';
    if (unreadNotifications === 1) return this.unreadNotificationsLabel = 'Una notificación sin leer';
    this.unreadNotificationsLabel = `${unreadNotifications} notificaciones sin leer`;
  };

  /**
   * Utiliza Server-Sent Events (SSE) para verificar si llegaron nuevas notificaciones.
   *
   * La función establece una conexión SSE para recibir notificaciones del servidor cada 5 segundos.
   * 
   * Cuando llegan nuevas notificaciones, se invoca la función `handleNewNotificationsReceived`.
   */
  listenForUserNotifications(): void {
    // const url = `${environment.baseUrl}/notifications/listen`;
    // const requestHeaders = {
    //   'x-EnterpriseId': this._authService.enterpriseId,
    //   'Authorization': this._authService.accessToken
    // };

    // //* Cada 5 segundos verifica si hay nuevas notificaciones, en caso de que las haya se envían en parsedRequest.data
    // this.sseClient
    //   .stream(url, { keepAlive: true, responseType: 'event' }, { headers: requestHeaders }, 'GET')
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe({
    //     next: (response: MessageEvent) => {
    //       if (!response.data) return;

    //       const parsedRequest: Response<UserNotification> = JSON.parse(response.data);
    //       if (parsedRequest.errors.length) return;

    //       const newNotificationReceived = parsedRequest.data.length;
    //       if (newNotificationReceived) {
    //         this.handleNewNotificationsReceived(parsedRequest.data);
    //       }
    //     }
    //   });
  }

  /**
   * Se ejecuta cada vez que el listener de notificaciones recibe una nueva notificación.
   *
   * Esta función se activa cuando llegan nuevas notificaciones y realiza las siguientes acciones:
   * 1. Llama a `getUserNotifications` para actualizar la lista de notificaciones del usuario.
   * 2. Muestra un snackbar con la información de la nueva notificación si la consola está activa.
   * 3. Si la consola está en segundo plano, muestra una notificación del navegador.
   * 
   * @param {UserNotification[]} notifications - Lista de nuevas notificaciones recibidas.
   */
  handleNewNotificationsReceived(notifications: UserNotification[]): void {
    this.getUserNotifications();

    function showSystemNotification(_notification: UserNotification): void {
      const notification = new Notification(_notification.title, {
        body: _notification.content,
        icon: `assets/icons/notifications/${_notification.iconName}.svg`
      });

      notification.onclick = () => this.openNotification(_notification);
    };

    notifications.forEach((_notification) => {
      //* Si el usuario se encuentra usando la consola se mostrará un snackbar con la información de la nueva notificación.
      if (this.consoleTabIsActive) {
        this._alertService.showNotificationSnackbar(_notification);
      } else { //* Si la consola se esta ejecutando en segundo plano se mostrará una notificación del navegador.
        this.checkSystemNotificationPermissions()
          .then((notificationsPermissionGranted: boolean) => {
            if (notificationsPermissionGranted) {
              showSystemNotification(_notification);
            }
          });
      }
    });
  }

  /**
   * Verifica y solicita permisos de notificación del navegador.
   *
   * @returns {Promise<boolean>} Una promesa que resuelve con `true` si el usuario autoriza las notificaciones,
   *                             y rechaza en otros casos (permisos denegados o no disponibles).
   */
  checkSystemNotificationPermissions(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!('Notification' in window)) {
        return reject();
      }

      if (Notification.permission === 'granted') {
        return resolve(true);
      }

      if (Notification.permission === 'denied') {
        return reject();
      }

      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            return resolve(true);
          }
          reject();
        });
    });
  }

  /**
   * Se ejecuta cuando el usuario hace click en la notificación.
   * 
   * Redirige al usuario a la url de la propiedad `redirectTo` y marca la notificación como leída.
   *
   * @param {UserNotification} notification - La notificación a abrir.
   */
  openNotification(notification: UserNotification): void {
    if (!notification.redirectTo) return;

    this.router.navigate([notification.redirectTo])
      .then(() => {
        if (notification.readAt) return;
        this.markNotificationAsRead(notification.notificationId);
      })
      .catch(() => this._alertService.showSnackbarAlert('Ha ocurrido un error al abrir la notificación'));
  }

  settingNotificationAsRead: boolean = false;

  /**
   * Marca como leída una notificación especifica.
   *
   * @param {number} notificationId - El ID de la notificación a ser marcada como leída.
   */
  markNotificationAsRead(notificationId: string): void {
    if (this.settingNotificationAsRead) return;
    
    this.settingNotificationAsRead = true;
    this.httpClient.put(`${environment.baseUrl}/notifications/${notificationId}/read`, null)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: () => {
          this.getUserNotifications();
          this.settingNotificationAsRead = false;
        },
        error: () => {
          this._alertService.showSnackbarAlert('Ha ocurrido un error al marcar la notificación como leída');
          this.settingNotificationAsRead = false;
        }
      });
  }

  /**
   * Elimina una notificación especifica.
   * En caso de que la petición haya sido exitosa, actualiza el Subject `_userNotifications` e informa el resultado al usuario mediante un snackbar.
   *
   * @param {number} notificationId - El ID de la notificación a ser eliminada.
   */
  deleteNotification(notificationId: string): void {
    this.isLoading = true;
    this.httpClient.delete(`${environment.baseUrl}/notifications/${notificationId}`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: () => {
          this.getUserNotifications('Notificación eliminada exitosamente');
        },
        error: () => this._alertService.showSnackbarAlert('Ha ocurrido un error al eliminar la notificación'),
        complete: () => this.isLoading = false
      });
  }


  /**
   * Envío de notificaciones.
   * 
   * Método utilizado en la pestaña de envío de notificaciones.
   */
  sendNotification(req): Observable<SingleResponse<NotificationsResponse>> {
    return this.httpClient.post<SingleResponse<NotificationsResponse>>(`${environment.baseUrl}/notifications/send`, req);
  }


  /**
   * Alterna el filtro para mostrar notificaciones no leídas o todas las notificaciones.
   * 
   * Si hay notificaciones no leídas:
   * 1. Alterna el valor de `showUnreadNotificationsOnly`.
   * 2. Establece `isLoading` en `true` en caso de que el Subject `_unreadUserNotifications` no tenga notificaciones cargadas, esto con el fin de que cuando el usuario filtra por primera vez, se muestre el loader al hacer la llamada a la API.
   * 3. Obtiene las notificaciones según el filtro actual y actualiza el observable `notifications`.
   * 
   * Si no hay notificaciones no leídas:, 
   * 1. Establece `showUnreadNotificationsOnly` en `false`, esto en caso de que el usuario se encuentre con el filtro activo y ya no haya notificaciones sin leer. 
   * 2. Actualiza el observable `notifications` con todas las notificaciones.
   * 3. Obtiene todas las notificaciones.
   */
  toggleUnreadFilter(): void {
    if (this.isLoading) return;

    if (this.totalUnreadNotifications > 0) {
      this.showUnreadNotificationsOnly = !this.showUnreadNotificationsOnly;

      const unreadNotificationsAreLoaded = this._unreadUserNotifications.value.length;
      if (!unreadNotificationsAreLoaded) {
        this.isLoading = true;
      };
    } else {
      this.showUnreadNotificationsOnly = false;
    }

    this.getUserNotifications();
  }

  /**
   * Función ejecutada desde el constructor del componente `userNotificationsDetail`.
   * Se ejecuta cuando se carga el componente para mostrar el loader en caso de que las notificaciones aún no se hayan cargado.
   */
  handleLoadingState(): void {
    if (!this.notificationsAreLoaded) {
      this.isLoading = true;
    };
  }

  /**
   * Asigna el valor a `consoleTabIsActive` cada vez que la visibilidad del documento cambia.
   */
  handleDocumentVisibilityChange(): void {
    document.addEventListener('visibilitychange', () => {
      this.consoleTabIsActive = document.visibilityState === 'visible';
    });
  }

  /**
   * Se ejecuta cuando hay un cambio en la paginación de las notificaciones.
   * 
   * Asigna las nuevas opciones de paginación seleccionadas por el usuario y obtiene las notificaciones para las opciones dadas. 
   * 
   * @param {PageEvent} event - Evento ejecutado por el cambio de pagina.
   */
  handlePaginationChange(event: PageEvent): void {
    this.paginationOptions = {
      ...this.paginationOptions,
      pageSize: event.pageSize,
      pageIndex: event.pageIndex,
    };
    this.getUserNotifications();
  }

  //* Métodos encargados de manejar el tiempo transcurrido desde el envío de las notificaciones

  notificationTimeAgoInterval: number; // Almacena el valor del setInterval
  hasRecentNotifications: boolean;     // Indica si hay notificaciones creadas en la última hora
  hasNotificationsToday: boolean;      // Indica si hay notificaciones creadas hoy
  timeAgoIntervalTime: number = 60000; // 1 minuto

  /**
   * Ejecuta un intervalo para actualizar el tiempo transcurrido desde la llegada de las notificaciones en función del tiempo actual.
   */
  runNotificationsTimeAgoInterval(): void {
    this.notificationTimeAgoInterval = setInterval(() => {

      this.checkForRecentNotifications();
      this.setTimeAgoIntervalTime();

      if (this.hasRecentNotifications || this.hasNotificationsToday) {
        this.setUserNotifications(this._userNotifications.value);
      } else {
        clearInterval(this.notificationTimeAgoInterval);
      }
    }, this.timeAgoIntervalTime);
  }

  /**
   * Verifica la existencia de notificaciones recientes con el fin de verificar si seguir ejecutando el intervalo o no.
   */
  checkForRecentNotifications(): void {
    const HOUR_DURATION_IN_MINUTES = 60;
    const DAY_DURATION_IN_MINUTES = 60 * 24;

    //* Fecha y hora del momento actual
    const currentDateTime = moment.utc().local().toDate();

    for (const notification of this._userNotifications.value) {
      const notificationCreationTime = moment.utc(notification.createdAt, 'MM/DD/YYYY, hh:mm:ss').local();
      const minutesSinceNotificationWasCreated = Math.abs(notificationCreationTime.diff(currentDateTime, 'minutes'));

      this.hasRecentNotifications = minutesSinceNotificationWasCreated <= HOUR_DURATION_IN_MINUTES;
      this.hasNotificationsToday = minutesSinceNotificationWasCreated <= DAY_DURATION_IN_MINUTES;

      //* Si encuentra alguna notificación creada en la última hora o en el día finaliza la ejecución
      if (this.hasRecentNotifications || this.hasNotificationsToday) break;
    }
  }

  /**
   * Establece el intervalo de tiempo para la actualización de la lista de notificaciones basado en la existencia de notificaciones recientes.
   *
   * - En caso de que haya notificaciones enviadas en la ultima hora, el intervalo se ejecutará cada un minuto.
   * - En caso de que no haya notificaciones enviadas en la ultima hora, pero si en el mismo día, el intervalo se ejecutará cada una hora.
   * - En caso de que ninguna de las condiciones anteriores no se cumplan el intervalo finalizará su ejecución.
   */
  setTimeAgoIntervalTime(): void {
    const MINUTE_DURATION_IN_MS = 60000;
    const HOUR_DURATION_IN_MS = 60000 * 60;

    if (this.hasNotificationsToday) {
      this.timeAgoIntervalTime = HOUR_DURATION_IN_MS;
    } else if (this.hasRecentNotifications) {
      this.timeAgoIntervalTime = MINUTE_DURATION_IN_MS;
    }
  }
}
