import { Pipe, PipeTransform } from '@angular/core';
import { IconTypeEnum } from '../enums/icon-type.enum';

@Pipe({
  name: 'iconType'
})
export class IconTypePipe implements PipeTransform {

  transform(value: string): string {
    return IconTypeEnum[value];
  }

}
