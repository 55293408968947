import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { TripsCommunicationService } from './trips-communication.service';
import { PostsResponse, Response } from 'app/types/response-types';
import { SocialPostGroup } from 'app/types/objects';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  constructor(
    private _httpClient: HttpClient, 
    private _tripsCommunication: TripsCommunicationService
  ) { }

  fetchPosts(tripId: number, tripGroupId: string, order: string, page: number, limit: number): Observable<PostsResponse> {
    return this._httpClient.get<PostsResponse>(`${environment.baseUrl}/trips/${tripId}/groups/${tripGroupId}/social/`, {
      params: {
        "page": String(page),
        "limit": String(limit),
        "field": "createdAt",
        "filter": "",
        "order": order
      }
    });
  }

  deletePosts(postsIds: string, tripId: number) {
    return this._httpClient.delete(`${environment.baseUrl}/trips/${tripId}/groups/1/social/posts/${postsIds}`);
  }

  deletePostImages(tripId: number, groupsIds: string, postId: string, postImagesIds: string) {
    return this._httpClient.delete(`${environment.baseUrl}/trips/${tripId}/groups/${groupsIds}/social/posts/${postId}/media/${postImagesIds}`);
  }

  createPost(tags: string, description: string, tripId: number, tripGroupIds: string): Observable<Response<any>> {
    return this._httpClient.post<Response<any>>(`${environment.baseUrl}/trips/${tripId}/groups/${tripGroupIds}/social/posts`, {
      tags: tags,
      description: description
    })
  }

  uploadImageToPost(tripId: number, tripGroupIds: string, userPostsIds: number[], file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this._httpClient.post(`${environment.baseUrl}/trips/${tripId}/groups/${tripGroupIds}/social/posts/${userPostsIds.join(",")}`, formData, {
      reportProgress: false,
      observe: "events"
    })
  }

  getGroups(tripId: number, page: number, limit: number): Observable<Response<SocialPostGroup>> {
    return this._httpClient.get<Response<SocialPostGroup>>(`${environment.baseUrl}/trips/${tripId}/social/groups/`, {
      params: {
        "page": String(page),
        "limit": String(limit)
      }
    });
  }

}
