<section id="header" class="py-3">
  <div id="title">Invita a tus pasajeros</div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</section>

<div class="content grid gap-8 p-6">
  <div class="grid gap-6 w-full">
    <p class="text-secondary text-center">Envía este código a tus pasajeros para que se sumen a tu viaje en Viaxlab</p>
    <p class="code text-center">{{ code }}</p>
    <app-alert iconName="warning" color="amber" [text]="'SHARED.DIALOGS.ONLY_PAX_CODE' | translate"></app-alert>
  </div>
  <p class="decorated m-0"><span>O</span></p>
  <div class="qr-code">
    <canvas id="canvas"></canvas>
    <button mat-flat-button color="primary" (click)="downloadImage()">
      <mat-icon>download</mat-icon>
      &nbsp;{{ "SHARED.DIALOGS.DOWNLOAD_QR" | translate }}</button>
  </div>
</div>
