<section id="header">
    <span id="title">
        Agregar imágenes
    </span>
</section>
<section id="main-section" class="p-6">
    <form class="flex gap-3">
        <mat-form-field class="flex-1">
            
            <input #unsplashInput matInput placeholder="Busca una imagen en Unsplash">
            <mat-hint class="mt-2"><strong>Ejemplo: </strong>Nueva York</mat-hint>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="search(unsplashInput.value)">
            <mat-icon>search</mat-icon>
            &nbsp;
            Buscar
        </button>
    </form>
    <mat-divider></mat-divider>
    <ng-container *ngIf="unsplashSelectedImages.length">
        <div class="py-4 flex justify-between">
            <div class="relative flex flex-col gap-4 mr-2">
                <span class="label">
                    Imágenes seleccionadas
                    <span class="text-secondary"> ({{ unsplashSelectedImages.length }})</span>
                </span>
                <span class="text-secondary text-sm">
                    Photo by 
                    <a class="underline" [href]="'https://unsplash.com/@' + unsplashSelectedImages[selectedImageIndex].user.username + '?utm_source=viaxlab&utm_medium=referral'">{{ unsplashSelectedImages[selectedImageIndex].user.name }}</a> 
                    on 
                    <a class="underline" href="https://unsplash.com/?utm_source=viaxlab&utm_medium=referral">Unsplash</a>
                </span>
                <button mat-flat-button color="warn" class="absolute bottom-0" (click)="removeImage()">
                    <mat-icon>close</mat-icon>
                    &nbsp;
                    Remover imagen
                </button>
            </div>
            <div class="w-6/12 rounded-md relative unsplash-image">
                <ng-container *ngFor="let image of unsplashSelectedImages">
                    <img 
                        loading="lazy"
                        [src]="unsplashSelectedImages[selectedImageIndex].urls.small"
                        [alt]="unsplashSelectedImages[selectedImageIndex].description"
                        [class.active-image]="image.id === unsplashSelectedImages[selectedImageIndex].id"
                        class="inactive-image"
                    >
                </ng-container>
                <div class="absolute w-full flex items-center justify-between px-2 py-1 bottom-label">
                    <span class="text-secondary text-sm text-white">
                        Imagen {{ selectedImageIndex + 1 }}/{{ unsplashSelectedImages.length }}
                    </span>
                    <div class="flex gap-1">
                        <button (click)="selectedImageIndex = selectedImageIndex - 1" [disabled]="selectedImageIndex === 0">
                            <mat-icon class="text-white">chevron_left</mat-icon>
                        </button>
                        <button (click)="selectedImageIndex = selectedImageIndex + 1" [disabled]="selectedImageIndex + 1 === unsplashSelectedImages.length">
                            <mat-icon class="text-white">chevron_right</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngIf="unsplashSearchResult.length; else placeholder">
        <div class="grid gap-4 py-4">
            <div class="label">Selecciona las imágenes que desees agregar</div>
            <div class="grid grid-cols-2 gap-2">
                <ng-container *ngFor="let image of unsplashSearchResult">
                    <div class="relative rounded-md unsplash-image cursor-pointer" [class.selected]="unsplashSelectedImagesIds.has(image.id)" (click)="selectUnsplashImage(image)">
                        <img *ngIf="unsplashSelectedImagesIds.has(image.id)" class="w-6 h-6 absolute" style="right: 8px; top: 8px;" src="assets/icons/green-check.svg">
                        <img class="h-full object-cover" [src]="image.urls.small" [alt]="image.description">
                        <div class="absolute w-full px-2 py-1 bottom-label">
                            <span class="text-secondary text-sm text-white">
                                Photo by 
                                <a class="underline" [href]="'https://unsplash.com/@' + image.user.username + '?utm_source=viaxlab&utm_medium=referral'">{{ image.user.name }}</a> 
                                on 
                                <a class="underline" href="https://unsplash.com/?utm_source=viaxlab&utm_medium=referral">Unsplash</a>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #placeholder>
        <div class="w-full flex items-center justify-center px-8 py-16">
            <img class="w-6/12" src="assets/images/unsplash.svg" alt="">
        </div>
    </ng-template>
</section>

<section id="action-buttons" class="buttons justify-end">
    <button mat-button color="warn" class="mr-2" mat-dialog-close>{{ "CANCEL" | translate }}</button>
    <button mat-flat-button color="primary" (click)="addImages()">
      Agregar imágenes
    </button>
</section>