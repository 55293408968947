import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const generalNotificationsFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    roles: ['', Validators.required],
    title: ['', Validators.required],
    content: ['', Validators.required],
  });
};

export const generalRecurrentNotificationsFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    frequencyPattern: [null, Validators.required],
    sendAt: ['12:00', Validators.required],
  });
};

export const generalScheduledNotificationsFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    offsetReference: [null, Validators.required],
    reference: [null, Validators.required],
    timeOffset: [null, Validators.required],
  });
};