import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TreoNavigationModule } from '@treo/components/navigation';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { ThemeToggleModule } from 'app/layout/common/theme-toggle/theme-toggle.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { SharedModule } from 'app/shared/shared.module';
import { FuturisticLayoutComponent } from 'app/layout/layouts/vertical/futuristic/futuristic.component';
import { TranslateModule } from '@ngx-translate/core';
import { SofiaModalComponent } from 'app/shared/dialogs/sofia-modal/sofia-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserNotificationsComponent } from '../../../common/user-notifications/user-notifications.component';

@NgModule({
    declarations: [
        FuturisticLayoutComponent,
        SofiaModalComponent,
        UserNotificationsComponent,
    ],
    imports: [
        HttpClientModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        TreoNavigationModule,
        MessagesModule,
        NotificationsModule,
        SearchModule,
        ShortcutsModule,
        ThemeToggleModule,
        UserModule,
        SharedModule,
        TranslateModule
    ],
    exports     : [
        FuturisticLayoutComponent
    ]
})
export class FuturisticLayoutModule
{
}
