import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const providerFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    providerId: ['', [Validators.required]],
    name: ['', [Validators.required]],
    document: ['', []],
    country: ['', []],
    type: ['', []],
    phone: ['', []]
  });
};
