import { Component } from '@angular/core';
import { TreoConfigService } from '../../../../@treo/services/config';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss']
})
export class ThemeToggleComponent {

  constructor(private _treoConfigService: TreoConfigService) { }

  public change(event: MatSlideToggleChange): void {
    this._treoConfigService.config = {theme: (event.checked ? 'dark' : 'light')};
  }

}
