import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { Response, SingleResponse } from '../types/response-types';
import { Group, GroupActivity, Passenger } from '../types/objects';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  
  constructor(
    private _httpClient: HttpClient
  ) { }

  reCalculateGroups = new Subject();

  getGroups(tripId: Number): Observable<Response<Group>> {
    return this._httpClient.get<Response<Group>>(environment.baseUrl + `/trips/${tripId}/groups`);
  }

  getGroupById(tripId: Number, tripGroupId: Number): Observable<SingleResponse<Group>> {
    return this._httpClient.get<SingleResponse<Group>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}`);
  }

  getPassengers(tripId: Number, tripGroupId: number): Observable<Passenger[]> {
    return this._httpClient.get<Passenger[]>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}/passengers`);
  }

  createGroup(tripId: number, groupData: any): Observable<SingleResponse<any>> {
    return this._httpClient.post<SingleResponse<any>>(environment.baseUrl + `/trips/${tripId}/groups`, groupData);
  }

  updateGroup(tripId: number, tripGroupId: number, groupData: any): Observable<SingleResponse<any>> {
    return this._httpClient.put<SingleResponse<any>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}`, groupData);
  }

  deleteGroup(tripId: number, tripGroupId: number): Observable<SingleResponse<any>> {
    return this._httpClient.delete<SingleResponse<any>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}`);
  }

  assignCoordinators(tripId: number, tripGroupId: number, req: any, generalCoordination?: boolean): Observable<SingleResponse<any>> {
    const url = environment.baseUrl + (generalCoordination ? `/trips/${tripId}/general-coordinators` : `/trips/${tripId}/groups/${tripGroupId}/coordinators`);
    return this._httpClient.post<SingleResponse<any>>(url, req);
  }
}
