import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Trip, Pagination, Passenger, Group } from '../types/objects';

@Injectable({
  providedIn: 'root'
})
export class TripsCommunicationService {
  private _trip: BehaviorSubject<Trip | null> = new BehaviorSubject(null);
  private _trips: BehaviorSubject<Trip[] | null> = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  private _tripPassengers: BehaviorSubject<Passenger[] | null> = new BehaviorSubject(null);
  private _tripPassengersPagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);
  private _tripGroups: BehaviorSubject<Group[] | null> = new BehaviorSubject(null);

  constructor() {
  }

  set tripGroups(groups: Group[] | null) {
    this._tripGroups.next(groups.filter(group => group.tripGroupId !== -1));
  }

  get trips$(): Observable<Trip[]> {
    return this._trips.asObservable();
  }

  get trip$(): Observable<Trip> {
    return this._trip.asObservable();
  }

  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  get tripPassengers$(): Observable<Passenger[]> {
    return this._tripPassengers.asObservable();
  }

  get tripPassengersPagination$(): Observable<Pagination> {
    return this._tripPassengersPagination.asObservable();
  }

  get getTripsValue(): Trip[] {
    return this._trips.value;
  }

  nextTrip(trip: Trip): void {
    this._trip.next(trip);
  }

  nextPagination(pagination: Pagination): void {
    this._pagination.next(pagination);
  }

  nextTrips(trips: Trip[]): void {
    this._trips.next(trips);
  }

  nextTripPassengers(passengers: Passenger[]): void {
    this._tripPassengers.next(passengers);
  }

  nextTripPassengersPagination(pagination: Pagination): void {
    this._tripPassengersPagination.next(pagination);
  }
}
