import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Response } from '../types/response-types';

@Injectable({
  providedIn: 'root'
})
export class WorldService {
  constructor(
    private _httpClient: HttpClient, 
  ) { }
  
  getCountries(): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.worldUrl + '/countries');
  }

  getStates(countryId: string): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.worldUrl + `/countries/${countryId}/states`);
  }
  
  getCities(countryId: string, stateId: string): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.worldUrl + `/countries/${countryId}/states/${stateId}/cities`);
  }
}
