<div class="header">
  <div class="title">{{ title | translate }}</div>
</div>
<div class="content">
  <p [innerHTML]="message | translate"></p>
</div>
<div class="buttons flex justify-end">
  <button class="mat-focus-indicator mat-button mat-button-base mat-warn mr-2"
          mat-button
          (click)="cancel()">
    <span class="mat-button-wrapper" >{{ "CANCEL" | translate }}</span>
  </button>
  <button class="mat-focus-indicator mat-flat-button mat-button-base mat-primary"
          mat-flat-button
          (click)="accept()">
    <span class="mat-button-wrapper" >{{ "CONFIRM" | translate }}</span>
  </button>
</div>
