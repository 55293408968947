export const environment = {
    production: false,
    version: "4.0.0-SNAPSHOT",
    baseUrl: "https://public-api-dev.viaxlab.com",
    storeUrl: "https://store-api-dev.viaxlab.com",
    chatUrl: "https://chat-api-dev.viaxlab.com/",
    worldUrl: "https://world-api-1-0-0.viaxlab.com/",
    pendoApiKey: 'df4d2810-c7b9-4183-4cd0-0e63cb1a5328',
    assetsBaseUrl: '',
};
