import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const clientFormGroup = (formbuilder: FormBuilder): FormGroup => {
  return formbuilder.group({
    enterpriseId: ['', []],
    name: ['', [Validators.required]],
    countryId: ['', [Validators.required]],
    notificationEmail: ['', [Validators.email]],
    planId: [''],
  });
};