import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appGrabScroll]'
})
export class GrabScrollDirective {
  pos: ScrollPos = new ScrollPos(0, 0, 0, 0);
  isGrabbing: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }


  @HostListener('mouseup') onMouseUp(): void {
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'grab');
    this.isGrabbing = false;
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent): void {
    this.pos = new ScrollPos(this.el.nativeElement.scrollLeft,
      this.el.nativeElement.scrollTop, event.clientX, event.clientY);
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'grabbing');
    this.isGrabbing = true;
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent): void {
    const dx = event.clientX - this.pos.x;
    const dy = event.clientY - this.pos.y;

    if (this.isGrabbing) {
      this.el.nativeElement.scrollTop = this.pos.top - dy;
      this.el.nativeElement.scrollLeft = this.pos.left - dx;
    }
  }
}

export class ScrollPos {
  public left: number;
  public top: number;
  public x: number;
  public y: number;

  constructor(left: number, top: number, x: number, y: number) {
    this.left = left;
    this.top = top;
    this.x = x;
    this.y = y;
  }
}
