<section class="card-section"
         [class.selected-card]="summaryCard.selected"
         (click)="onSelection()">

      <div id="summary-name">
        {{ summaryCard?.title | summaryTitle | translate }}
      </div>

      <div class="ml-auto font-bold flex items-center">
        <div class="flex align-middle w-5 justify-center" *ngIf="!this.summaryCard?.maxPassengers; else passengerCounter">
          {{ summaryCard?.complementaryTitle }}
        </div>
        <mat-icon
                  class="ml-1 fs-1"
                  id="passenger_icon">
                  {{ iconType }}
        </mat-icon>

        <ng-template #passengerCounter>
          <div class="flex items-center" id="passenger_counter" [ngClass]="this.maxPassengersExceeded ? 'warning' : 'safe'">
            {{ summaryCard?.complementaryTitle }} / {{ this.summaryCard?.maxPassengers }}
          </div>
        </ng-template>
      </div>

    <div class="excerpt">
      {{ summaryCard?.content | slice:0:80 }}
      <ng-container *ngIf="summaryCard?.content?.length > 80">...</ng-container>
    </div>
</section>
