import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { AlertsService } from 'app/services';
import { AuthService } from 'app/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ImageSelectorOptionsComponent } from './components/image-selector-options/image-selector-options.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageUrlModalComponent } from './components/image-url-modal/image-url-modal.component';
import { UnsplashModalComponent } from './components/unsplash-modal/unsplash-modal.component';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit {

  @Input() imageUrls: string[];
  @Output() handleAddedImage: EventEmitter<string[]> = new EventEmitter();
  @ViewChild('uploadInput') uploadInput: ElementRef<HTMLInputElement>;

  constructor(
    private _alertService: AlertsService,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    ) {
      this.initImageLoading();
    }

  ngOnInit(): void {
  }
  
  isUploading = false;
  uploader: FileUploader;
  uploaderResponse: string;

  openImageSelectorOptions(): void {
    const bottomSheet = this._bottomSheet.open(ImageSelectorOptionsComponent);
    bottomSheet
      .afterDismissed()
      .subscribe((result: ('url' | 'unsplash' | 'device')) => { 
        if (result) {
          if (result === 'device') {
            this.uploadInput.nativeElement.click();
          }

          if (result === 'unsplash') {
            this.dialog.open(UnsplashModalComponent, {
              width: "40rem",
              disableClose: true
            })
            .afterClosed()
            .subscribe((imageUrls) => {
              if (!imageUrls) return;
              
              this.imageUrls = [...this.imageUrls, ...imageUrls];
              this.handleAddedImage.emit(this.imageUrls);
            })
          }

          if (result === 'url') {
            this.dialog.open(ImageUrlModalComponent, {
              width: "40rem",
              disableClose: true
            })
            .afterClosed()
            .subscribe((imageUrl) => {
              if (!imageUrl) return;

              this.imageUrls = [...this.imageUrls, imageUrl];
              this.handleAddedImage.emit(this.imageUrls);
            })
          }
        }
      })
  }

  removeImage(imageUrl: string) {
    this.imageUrls = this.imageUrls.filter((image) => image !== imageUrl);
    this.handleAddedImage.emit(this.imageUrls);
  }

  private initImageLoading(): void {
    this.uploader = new FileUploader({
      url: environment.baseUrl + '/images/upload',
      headers: [
        { name: 'Accept', value: 'application/json' },
        { name: 'Authorization', value: this._authService.accessToken },
        { name: 'X-EnterpriseId', value: this._authService.enterpriseId ?? 'ERROR' }
      ],
      allowedMimeType: ['image/png', 'image/gif', 'image/jpeg'],
      additionalParameter: {
        resource_type: "service-images"
      },
      disableMultipart: false
    });

    this.uploader.onBeforeUploadItem = item => {
      item.withCredentials = false;
      this.isUploading = true;
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      const data = JSON.parse(response);
      this.imageUrls = [...this.imageUrls, data.data.url];
      this.handleAddedImage.emit(this.imageUrls);
      
      this.isUploading = false;
    };

    this.uploader.onAfterAddingFile = file => {
      if (file.file.size >= 5000000) {
        this._alertService.showSnackbarAlert(this._translateService.instant('SHARED.DIALOGS.MAX_FILE_SIZE'))
      } else {
        this.uploader.uploadItem(file);
      }
    };

    this.uploaderResponse = '';
    this.uploader.response.subscribe(res => {
      this.uploaderResponse = res;
    });

    this.uploader.onWhenAddingFileFailed = (item) => {
      alert("Formato de imagen no soportado");
    }
  }

}
