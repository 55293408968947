import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Response } from '../types/response-types';
import { Document } from 'app/types/objects/document.type';

@Injectable({
  providedIn: 'root'
})
export class CoordinatorsService {
  
  constructor(
    private _httpClient: HttpClient
  ) { }

  all(
    page: number = 1,
    size: number = 10,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    filter: string = '',
    generalCoordination: boolean = false,
    tripId: number = null,
  ): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.baseUrl + (generalCoordination ? `/enterprise/general-coordinators` : `/trips/${tripId}/coordinators`), {
      params: {
        page: '' + page,
        limit: '' + 9999,
        filter,
      }
    });
  }

  getTripGeneralCoordinators(tripId: number): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(`${environment.baseUrl}/trips/${tripId}/general-coordinators`);
  }

  // Documents

  getCoordinatorDocuments(tripId: number, coordinatorId: number): Observable<Document[]> {
    return this._httpClient.get<Document[]>(`${environment.baseUrl}/trips/${tripId}/coordinators/${coordinatorId}/documents`);
  }

  uploadDocument(formData: FormData, tripId: number, coordinatorId: number): Observable<Document> {
    return this._httpClient.post<Document>(`${environment.baseUrl}/trips/${tripId}/coordinators/${coordinatorId}/documents`, formData);
  }

  updateDocument(tripId: number, coordinatorId: number, documentId: number, documentName: string): Observable<null> {
    const body = JSON.stringify({ name: documentName });
    return this._httpClient.put<null>(`${environment.baseUrl}/trips/${tripId}/coordinators/${coordinatorId}/documents/${documentId}`, body);
  }

  deleteDocument(tripId: number, coordinatorId: number, documentId: number): Observable<null> {
    return this._httpClient.delete<null>(`${environment.baseUrl}/trips/${tripId}/coordinators/${coordinatorId}/documents/${documentId}`);
  }
}