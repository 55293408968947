import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EnterpriseService } from 'app/services/enterprise.service';

@Component({
  selector: 'app-enterprise-activations',
  templateUrl: './enterprise-activations.component.html',
  styleUrls: ['./enterprise-activations.component.scss']
})
export class EnterpriseActivationsComponent implements OnInit, AfterViewInit {

  @ViewChild('progress') progressBar: ElementRef;

  showLimitReachedAlert: boolean;
  showLimitWarningAlert: boolean;

  constructor(public _enterpriseService: EnterpriseService) {
    _enterpriseService.enterprisePlan.subscribe((plan) => {
      if (!plan?.planId) return;

      this.updateProgressBar();
      this.showLimitReachedAlert = plan.currentPaxActivation >= plan.maxPaxActivation;
      this.showLimitWarningAlert = plan.maxPaxActivation - 20 <= plan.currentPaxActivation;
    })
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.updateProgressBar();
  }

  updateProgressBar(): void {
    const progressUnitValue = 100 / this._enterpriseService.enterprisePlan.value?.maxPaxActivation;
    const currentActivations = progressUnitValue * this._enterpriseService.enterprisePlan.value?.currentPaxActivation;
    if (this.progressBar) this.progressBar.nativeElement.style.width = `${currentActivations}%`;
  }
}
