import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

export const tripFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    name: ['', Validators.required],
    startDate: [''],
    endDate: [''],
    medicalRecord: [false]
  });
};

export const dateRangeValidator: ValidatorFn | null = (fg: FormGroup) => {
  const startDate = new Date(fg.controls.startDate.value);
  const endDate = new Date(fg.controls.endDate.value);
  return endDate >= startDate ? null : { error: true };
};
