import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group } from 'app/types/objects';
import { Activities, ActivityTracking, ActivityTrackingResponse, ExportDataResponse, PassengerTracking, PassengerTrackingResponse } from 'app/types/objects/tracking-metrics.type';
import { environment } from 'environments/environment';
import moment from 'moment';
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { GroupsService } from './groups.service';
import { PageEvent } from '@angular/material/paginator';
import { AlertsService } from './alerts.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

interface ActivitiesSection {
  title: string;
  icon: string;
  activities: ActivityTracking[];
}[];

interface FilterTerms {
  activity?: string;
  passenger?: string;
  coordinator?: string;
}[];

@Injectable({
  providedIn: 'root'
})
export class TrackingMetricsService {

  public _unsubscribeAll: Subject<void> = new Subject<void>();

  activities: BehaviorSubject<Activities> = new BehaviorSubject(null);
  selectedActivity: BehaviorSubject<ActivityTracking> = new BehaviorSubject(null);
  passengers: BehaviorSubject<PassengerTracking[]> = new BehaviorSubject(null);

  isLoading: boolean;
  activitiesLoading: boolean;
  passengersLoading: boolean;

  tripId: number;
  selectedInstance: 'checkIn' | 'checkOut' = 'checkIn';
  selectedGroup: Group;
  groups: Group[] = [];
  activitiesBySection: ActivitiesSection[] = [];
  showOnlyTrackedPassengers = false;

  noData = {
    activities: false,
    passengers: false,
  };

  instances = [{
    label: 'Check In',
    value: 'checkIn'
  },
  {
    label: 'Check Out',
    value: 'checkOut'
  }];

  _filterTerms = {
    activity: new Subject(),
    passenger: new Subject(),
    coordinator: new Subject(),
  }

  filterTerms: FilterTerms = {
    activity: '',
    passenger: '',
    coordinator: '',
  }


  paginationOptions = {
    activity: {
      currentPageSize: 25,
      basePageSize: 25,
      totalActivities: 0
    },
    passenger: {
      pageIndex: 0,
      pageSize: 10,
      length: 0,
    }
  };

  constructor(
    private httpClient: HttpClient,
    private _groupService: GroupsService,
    private _alertService: AlertsService
  ) { }

  getTripActivities() {
    let url = `${environment.baseUrl}/monitor/trips/${this.tripId}/tracking?limit=${this.paginationOptions.activity.currentPageSize}`;
    if (this.selectedGroup && this.selectedGroup.tripGroupId !== -1) {
      url += `&groups=${this.selectedGroup.tripGroupId}`;
    }
    if (this.filterTerms?.activity) {
      url += `&activity=${this.filterTerms.activity}`;
    }

    this.httpClient
      .get<ActivityTrackingResponse>(url)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.noData.activities = response.total === 0;
          this.activities.next(response.data);
          this.getActivitiesBySection(response.data);
          this.paginationOptions.activity.totalActivities = response.total;
          this.activitiesLoading = false;
          this.isLoading = false;

          if (this.selectedActivity.value && !this.filterTerms.activity) {
            this.selectedActivity.next(null);
            this.passengers.next(null);
          }
        },
        error: (error) => {
          this.activitiesLoading = false;
          this.isLoading = false;
          if (error.status == 429) {
            this._alertService.showSnackbarAlert(error.error.detail);
          } else {
            this._alertService.showSnackbarAlert('Ha ocurrido un error al obtener las actividades del viaje');
          }
        }
      });
  }

  getActivitiesPassengers() {
    let url = `${environment.baseUrl}/monitor/trips/${this.tripId}/tracking/passengers?limit=${this.paginationOptions.passenger.pageSize}&page=${this.paginationOptions.passenger.pageIndex}&activities=${this.selectedActivity.value?.activityIds}&instance=${this.selectedInstance}`;

    if (this.selectedGroup && this.selectedGroup.tripGroupId !== -1) {
      url += `&groups=${this.selectedGroup.tripGroupId}`;
    }
    if (this.filterTerms?.coordinator) {
      url += `&coordinator=${this.filterTerms.coordinator}`;
    }
    if (this.filterTerms?.passenger) {
      url += `&passenger=${this.filterTerms.passenger}`;
    }
    if (this.showOnlyTrackedPassengers) {
      url += `&onlyTracked=${this.showOnlyTrackedPassengers}`;
    }

    this.httpClient
      .get<PassengerTrackingResponse>(url)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.noData.passengers = response.total === 0;
          this.passengers.next(response.data.inActivityPassengers);
          this.paginationOptions.passenger.length = response.total;

          this.passengersLoading = false;
          this.isLoading = false;
        },
        error: (error) => {
          this.passengersLoading = false;
          this.isLoading = false;
          this._alertService.showSnackbarAlert('Ha ocurrido un error al obtener los pasajeros de la actividad');
        }
      });
  }

  getGroups(): void {
    this._groupService.getGroups(this.tripId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: response => {
          this.groups = response.data;

          const { passengersTotal, coordinatorsTotal } = this.groups.reduce((totals, group) => {
            totals.passengersTotal += group.passengersQty;
            totals.coordinatorsTotal += group.coordinatorsQty;
            return totals;
          }, { passengersTotal: 0, coordinatorsTotal: 0 });


          const allGroup = {
            name: "Todos",
            coordinatorsQty: coordinatorsTotal,
            passengersQty: passengersTotal,
            maxPassengers: null,
            tripGroupId: -1,
            coordinators: []
          };

          this.groups.unshift(allGroup);

          this.selectedGroup = allGroup;
          this.getTripActivities();
        }
      })
  }

  exportData(type: 'activity' | 'passenger'): void {
    let url = `${environment.baseUrl}/monitor/trips/${this.tripId}/tracking`;

    if (type === 'activity') {
      this.activitiesLoading = true;
      url += '/export?';
    } else {
      url += `/passengers/export?activities=${this.selectedActivity.value.activityIds}`;
      this.passengersLoading = true;
    }

    if (this.selectedGroup && this.selectedGroup.tripGroupId !== -1) {
      url += `${type === 'passenger' ? '&' : ''}groups=${this.selectedGroup.tripGroupId}`;
    }

    this.httpClient
      .get<ExportDataResponse>(url)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response) => {
          var a = document.createElement('a');
          a.href = response.data.url;
          a.download = response.data.name;
          document.body.appendChild(a);
          a.click();
          a.remove();

          this.activitiesLoading = false;
          this.passengersLoading = false;
        },
        error: (error) => {
          this.activitiesLoading = false;
          this.passengersLoading = false;
          this._alertService.showSnackbarAlert('Ha ocurrido un error al exportar los datos');
        }
      });
  }

  handleGroupChange(group: Group): void {
    const notCurrentSelectedGroup = group.tripGroupId !== this.selectedGroup?.tripGroupId;

    if (notCurrentSelectedGroup) {
      this.selectedGroup = group;
      this.activitiesLoading = true;
      this.getTripActivities();
    }
  }

  handleActivitySelection(activity: ActivityTracking): void {
    const notCurrentSelectedActivity = activity.activityIds.join(',') !== this.selectedActivity.value?.activityIds.join(',');

    if (notCurrentSelectedActivity) {
      this.selectedActivity.next(activity);
      this.passengersLoading = true;
      this.paginationOptions.passenger.pageIndex = 0;
      this.getActivitiesPassengers();
    }
  }

  getBadgeLabel(activity: ActivityTracking): string {
    return `${activity.totalTrackedPassengers[this.selectedInstance]}/${activity.totalPassengers}`;
  }

  getBadgeColor(activity: ActivityTracking): string {
    if (activity.totalTrackedPassengers[this.selectedInstance] === 0) {
      return 'red';
    }
    if (activity.totalTrackedPassengers[this.selectedInstance] === activity.totalPassengers) {
      return 'green';
    }
    if (activity.totalTrackedPassengers[this.selectedInstance] !== activity.totalPassengers) {
      return 'yellow';
    }
  }

  getActivitiesBySection(activities: Activities): void {
    const formattedActivities: ActivitiesSection[] = [];

    for (let key in activities) {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

      const getTitle = (): string => {
        const isDate = key.match(dateRegex);

        if (isDate) {
          moment.locale('es');
          const formattedDate = moment(key).format('dddd D [de] MMMM').replace(/^\w/, (c) => c.toUpperCase());
          return formattedDate;
        } else {
          return key === 'optionals'
            ? 'Actividades opcionales'
            : 'Sin fecha asignada';
        }
      };

      const getIcon = (): string => {
        const isDate = key.match(dateRegex);

        if (isDate) {
          return 'event';
        } else {
          return key === 'optionals' ? 'radio_button_unchecked' : 'event_busy';
        }
      };

      formattedActivities.push({
        title: getTitle(),
        icon: getIcon(),
        activities: activities[key],
      });
    }

    this.activitiesBySection = formattedActivities;
  }

  handleInputFilterChange(type: 'activity' | 'passenger' | 'coordinator', event: KeyboardEvent): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTerms[type] = filterValue;
    this._filterTerms[type].next(filterValue);
  }

  handleOnlyTrackedPassengersFilterChange(event: MatCheckboxChange) {
    this.showOnlyTrackedPassengers = event.checked;
    this.passengersLoading = true;
    this.getActivitiesPassengers();
  }

  listenForFilterChanges() {
    this._filterTerms.activity
      .pipe(debounceTime(300),
        distinctUntilChanged((x, y) => x === y),
        map(() => {
          this.activitiesLoading = true;
          this.getTripActivities();
        })).subscribe();

    this._filterTerms.coordinator
      .pipe(debounceTime(300),
        distinctUntilChanged((x, y) => x === y),
        map(() => {
          this.passengersLoading = true;
          this.getActivitiesPassengers();
        })).subscribe();

    this._filterTerms.passenger
      .pipe(debounceTime(300),
        distinctUntilChanged((x, y) => x === y),
        map(() => {
          this.passengersLoading = true;
          this.getActivitiesPassengers();
        })).subscribe();
  }

  handlePageChanges(event: PageEvent): void {
    this.paginationOptions.passenger.length = event.length;
    this.paginationOptions.passenger.pageSize = event.pageSize;
    this.paginationOptions.passenger.pageIndex = event.pageIndex;
   
    this.passengersLoading = true;
    this.getActivitiesPassengers();
  }
}
