import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AsideService {
  toggleAside: Subject<void> = new Subject()
  asideOpen: boolean = false;

  constructor(
  ) {}

  toggle(){
    this.asideOpen = !this.asideOpen;
    this.toggleAside.next()
  }

}
