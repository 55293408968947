import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-image-selector-options',
  templateUrl: './image-selector-options.component.html',
  styleUrls: ['./image-selector-options.component.scss']
})
export class ImageSelectorOptionsComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<ImageSelectorOptionsComponent>) { }

  ngOnInit(): void {
  }

  handleOptionSelect(option: 'url' | 'unsplash' | 'device'): void {
    this._bottomSheetRef.dismiss(option);
  }
}
