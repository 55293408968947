import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Compressor from 'compressorjs';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor() { }

  compressImage(image: File): Subject<File> {
    const maxSize = 200 * 1024;
    let quality = 0.9;

    const compressedImage = new Subject<File>();
    if (image.size <= maxSize) {
      setTimeout(() => compressedImage.next(image), 0);
    } else {
      const compress = () => {
        new Compressor(image, {
          quality,
          // maxWidth: 1080,
          mimeType: image.type,
          success(result: Blob) {

            const file = new File([result], image.name, {
              type: image.type,
              lastModified: Date.now(),
            });

            if (file.size > maxSize && quality > 0.3) {
              quality -= 0.1;
              compress();
            } else {
              compressedImage.next(file);
              compressedImage.complete();
            }
          }
        });
      };
      compress();
    }

    return compressedImage;
  }
}
