import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

export const tutorFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    email: [null, [Validators.email]],
    lastName: [null, [Validators.required]],
    name: [null, [Validators.required]],
    phone: [null, [Validators.pattern(/^[0-9+]+$/)]],
    language: [null, [Validators.required]],
    medicalRecord: [null, [Validators.required]],
  },
  { 
    validators: [validateEmailOrPhoneTutor]
  });
};

export function validateEmailOrPhoneTutor(control: AbstractControl): ValidationErrors | null {
  const email = control.get('email').value;
  const phone = control.get('phone').value;
  return email && email !== "" || phone && phone !== "" ? null : { invalid: true};
}
