import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Provider, Pagination } from '../types/objects';

@Injectable()
export class ProvidersCommunicationService {
  private _provider: BehaviorSubject<Provider | null> = new BehaviorSubject(null);
  private _providers: BehaviorSubject<Provider[] | null> = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

  constructor() {
  }

  get providers$(): Observable<Provider[]> {
    return this._providers.asObservable();
  }

  get provider$(): Observable<Provider> {
    return this._provider.asObservable();
  }

  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  get getProvidersValue(): Provider[] {
    return this._providers.value;
  }

  nextProvider(provider: Provider): void {
    this._provider.next(provider);
  }

  nextPagination(pagination: Pagination): void {
    this._pagination.next(pagination);
  }

  nextProviders(providers: Provider[]): void {
    this._providers.next(providers);
  }
}
