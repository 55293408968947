import { Pipe, PipeTransform } from '@angular/core';
import { DayTypeEnum } from '../enums/day-type.enum';

@Pipe({
  name: 'dayType'
})
export class DayTypePipe implements PipeTransform {

  transform(value: string): string {
    return DayTypeEnum[value];
  }

}
