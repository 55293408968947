import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const ActivityDateAssignationFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    shortDescription: ['', Validators.required],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    formId: [null],
    serviceId: [null],
    itineraryId: [null],
    type: ['ITINERARY_DAY_ACTIVITY'],
  });
};
