import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SummaryCard } from '../../../types/objects';

@Component({
  selector: 'cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.scss'],
})
export class CardsListComponent implements OnInit, OnDestroy {
  @Input() iconType: string;
  @Input() cardsList: SummaryCard[];
  @Output() selectedCardChanged = new EventEmitter<SummaryCard>();
  selectedCard: SummaryCard;

  // Private
  private _unsubscribeAll: Subject<void>;

  constructor() {
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onSelection(summaryCard: SummaryCard): void {
    this.selectedCard = summaryCard;
    this.selectedCard.selected = true;
    this.cardsList = this.cardsList.map((card) => {
        card.selected = card.cardId === this.selectedCard.cardId;
        return card ;
      });
    this.selectedCardChanged.emit(summaryCard);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
