import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const tripCodeFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    name: ['', [Validators.required]],
    tripGroupId: ['', [Validators.required]],
    active: ['', [Validators.required]]
  }, {
    updateOn: 'blur'
  });
};
