import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ReadTripGroupResponse, Response } from '../types/response-types';
import { Coordinator, CreateGroupDto, Group, SummaryCard } from '../types/objects';
import { TripGroupsCommunicationService } from './trip-groups-communication.service';
import { LIST_PAGE_SIZE_DEFAULT } from 'app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class TripGroupsService {

  groupSelectionSubject: Subject<void> = new Subject<void>();
  groupUpdateSubject: Subject<any> = new Subject<any>();

  constructor(private _httpClient: HttpClient, private _tripGroupsCommunication: TripGroupsCommunicationService) {
  }

  getTripGroupById(tripId: number, id: number): Observable<Group> {
    return this._httpClient.get<ReadTripGroupResponse>(environment.baseUrl + `/trips/${tripId}/groups/${id}`).pipe(
      map((tripGroupResponse: ReadTripGroupResponse) => {
        return tripGroupResponse.data;
      })
    );
  }

  getTripGroupsAsCardsByField(tripId: number, groupType: string, page: number = 1, size: number = LIST_PAGE_SIZE_DEFAULT,
                              sort = 'name', order: 'asc' | 'desc' | '' = 'asc', filter: string = ''
  ): Observable<Response<any>> {
    return this._httpClient
      .get<Response<any>>(environment.baseUrl + `/trips/${tripId}/groups/fields/`, {
        params: { page: '' + page, limit: '' + size, sort, order, filter, field: groupType }
      })
      .pipe(
        tap((tripGroupsResponse: Response<any>) => {
          this._tripGroupsCommunication.nextPaginationBy(tripGroupsResponse.total, 10, page);
          const result: SummaryCard[] = [];
          tripGroupsResponse.data.forEach(tg => {
            let cardId: number;
            if (tg.field === 'all') {
              cardId = -1;
            } else if (tg.field === 'unassigned') {
              cardId = tg.field;
            } else {
              cardId = tg.groupId ? tg.groupId : tg.groupName;
            }
            result.push({
              cardId: cardId,
              title: tg.field ? tg.field : tg.groupName,
              complementaryTitle: '' + tg.totalPassengersInGroup,
              maxPassengers: tg.maxPassengers ? tg.maxPassengers : null,
              content: '',
              selected: false
            });

          });
          this._tripGroupsCommunication.nextTripGroupsBy(result);
        })
      );
  }

  getTripGroupPassengers(
    tripId: number,
    groupType: string,
    groupValue: string,
    page: number = 1,
    size: number = 50,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    filter: string = ''
  ): Observable<Response<any>> {
    return this._httpClient
      .get<Response<any>>(environment.baseUrl + `/trips/${tripId}/groups/passengers/field/`, {
        params: {
          page: '' + page,
          limit: '' + size,
          sort,
          order,
          filter,
          tripId: '' + tripId,
          field: groupType,
          value: groupValue
        }
      })
      .pipe(
        tap((tripGroupPassengersResponse: Response<any>) => {
          this._tripGroupsCommunication.nextGroupPagination(groupType, {
            length: tripGroupPassengersResponse.total,
            size: size,
            page: page,
            lastPage: Math.ceil(tripGroupPassengersResponse.total / size),
            startIndex: size * page,
            endIndex: size * page + Math.min(tripGroupPassengersResponse.total, size)
          });
          this._tripGroupsCommunication.nextGroupPassengers(groupType, tripGroupPassengersResponse.data);
        })
      );
  }

  getCoordinators(page: number = 1, size: number = 10000): Observable<Response<Coordinator>> {
    return this._httpClient.get<Response<Coordinator>>(environment.baseUrl + '/coordinators/', {
      params: {
        page: '' + page,
        limit: '' + size,
      }
    });
  }
  createNewGroup(tripId: number, createGroupDto: CreateGroupDto): Observable<Response<Group>> {
    return this._httpClient.post<Response<Group>>(environment.baseUrl + `/trips/${tripId}/groups`, createGroupDto);
  }

  updateTripGroup(tripId: number, tripGroupId: number, createGroupDto: CreateGroupDto): Observable<Response<Group>> {
    return this._httpClient.put<Response<Group>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}`, createGroupDto);
  }

  deleteTripGroup(tripId: number, tripGroupId: number): Observable<Response<any>> {
    return this._httpClient.delete<Response<any>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}`, {
     params: {
       tripGroupId: tripGroupId.toString(10)
     }
    });
  }

  sendSelectedGroupDeletion(): void {
    this.groupSelectionSubject.next();
  }

  listenForGroupDeletion(): Observable<any> {
    return this.groupSelectionSubject.asObservable();
  }

  listenForGroupUpdate(): Observable<any> {
    return this.groupUpdateSubject.asObservable();
  }

  sendGroupUpdate(group): void {
    this.groupUpdateSubject.next(group);
  }
}
