import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

export function requireOneOf(controls: string[]): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const values = controls.map(controlName => formGroup.get(controlName)?.value);
    const hasAtLeastOne = values.some(value => value && value.trim().length > 0);
    
    if (hasAtLeastOne) {
      // Si al menos uno de los campos tiene valor, limpiamos los errores en el grupo
      controls.forEach(controlName => {
        formGroup.get(controlName)?.setErrors(null);
      });
      return null;
    } else {
      // Si ninguno de los campos tiene valor, configuramos el error en el grupo
      controls.forEach(controlName => {
        formGroup.get(controlName)?.setErrors({ 'requireOneOf': true });
      });
      return { 'requireOneOf': true };
    }
  };
}

export const tripProposalFormGroup = (formBuilder: FormBuilder): FormGroup => {
  const formGroup = formBuilder.group({
    title: ['', [Validators.required, Validators.maxLength(50)]],
    information: '',
    contactPhone: '',
    contactEmail: '',
    currency: ['', Validators.required],
    price: ['', [Validators.required, Validators.pattern(/^(?!0(?:\.0{1,2})?$)[0-9]*(\.[0-9]{1,2})?$/)]],
    priceClarifications: '',
    financing: false,
    installmentsQuantity: [0, [Validators.min(1), Validators.pattern(/^[0-9]+$/)]],
  });

  formGroup.setValidators(requireOneOf(['contactEmail', 'contactPhone']));

  return formGroup;
};