import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const productFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(100)]],
    description: [null, [Validators.required]],
    currency: [null, [Validators.required]],
    price: [null, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]{0,2})?$/)]],
    stock: [null, [Validators.required, Validators.min(1)]],
    category: [null, [Validators.required]],
    active: [null, [Validators.required]],
    paymentProcessors: [[], Validators.required],
    paymentLink: [null, [Validators.required, Validators.pattern(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)]]
  });
};
