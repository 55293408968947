import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { EnterpriseService } from 'app/services/enterprise.service';

@Directive({
  selector: '[appPlanRestrictions]'
})
export class PlanRestrictionsDirective {
  @Input() featureId: number;

  constructor(private _enterpriseService: EnterpriseService, private elementRef: ElementRef, private renderer: Renderer2, private tooltip: MatTooltip) { }
  
  ngAfterViewInit() { 
    if (!this._enterpriseService.enterprisePlan.value || !this.featureId) return;
    if (this._enterpriseService.enterprisePlanFeaturesIds.has(this.featureId)) return;
    
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', true);
    
    const elementType = this.elementRef.nativeElement.nodeName;
    if (elementType === 'BUTTON') {
      this.renderer.addClass(this.elementRef.nativeElement, 'mat-button-disabled');
    }

    this.tooltip.message = 'Esta funcionalidad está deshabilitada debido a restricciones del plan contratado';
  }
}
