import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  SingleResponse
} from '../types/response-types';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SelfServiceService {
  constructor(private _httpClient: HttpClient) { }

  create(req: any): Observable<SingleResponse<any>> {
    return this._httpClient.post<SingleResponse<any>>(environment.baseUrl + `/self-service`, req);
  }

  verifyCaptcha(token: string): Observable<any> {
    return this._httpClient.post<any>(environment.baseUrl + `/recaptcha`, { token });
  }
}
