import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeToggleComponent } from './theme-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [ThemeToggleComponent],
    imports: [
        CommonModule,
        MatSlideToggleModule,
        FormsModule
    ],
  exports: [
    ThemeToggleComponent
  ]
})
export class ThemeToggleModule { }
