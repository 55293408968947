import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {
  CreateTripResponse,
  Response,
  UpdateTripResponse,
  ReadTripResponse,
  AddPassengerResponse,
  SingleResponse
} from '../types/response-types';
import { ImportPassengers, Passenger, Trip, TripCode, TripInformation, TripPayload } from '../types/objects';
import { AddPassengerRequest, CreateTripCodeRequest, UpdatePassengerRequest } from '../types/request-types';
import { LIST_PAGE_SIZE_DEFAULT } from '../shared';
import { TripsCommunicationService } from './trips-communication.service';
import { GetTripsDto } from '../modules/admin/trips/dtos';

@Injectable({
  providedIn: 'root'
})
export class TripsService {
  constructor(
    private _httpClient: HttpClient, 
    private _tripsCommunication: TripsCommunicationService
  ) { }

  updateTrip(id: number, trip: Trip): Observable<UpdateTripResponse> {
    return this._httpClient.put<UpdateTripResponse>(environment.baseUrl + `/trips/${id}`, trip);
  }

  deleteTrip(tripId): Observable<any> {
    return this._httpClient.delete<any>(environment.baseUrl + `/trips/${tripId}`);
  }

  getTrips(getTripsDto?: GetTripsDto): Observable<Response<Trip>> {
    const params = {
      tripStatus: getTripsDto.tripStatus,
      page: getTripsDto.page.toString(10),
      limit: getTripsDto.size.toString(10),
      field: getTripsDto.field,
      order: getTripsDto.order,
      filter: getTripsDto.filter || ''
    };
    return this._httpClient.get<Response<Trip>>(environment.baseUrl + '/trips/', {
        params
      });
  }

  getTripById(id: Number): Observable<Trip> {
    return this._httpClient.get<ReadTripResponse>(environment.baseUrl + `/trips/${id}`).pipe(
      map((tripResponse: ReadTripResponse) => {
        // this._tripsCommunication.nextTrip(tripResponse.data);
        return tripResponse.data;
      })
    );
  }

  newTrip(trip: Trip): void {
    const trips = this._tripsCommunication.getTripsValue;

    if (trips.find(item => item.tripId === null)) {
      trips.shift();
    }

    this._tripsCommunication.nextTrips([trip, ...trips]);
  }

  cancelNewTrip(): Observable<boolean> {
    return this._tripsCommunication.trips$.pipe(
      take(1),
      map((trips: Trip[]) => {
        if (trips.find(item => item.tripId === null)) {
          trips.shift();
        } else {
          return false;
        }

        this._tripsCommunication.nextTrips(trips);
        return true;
      })
    );
  }

  createTrip(trip: TripPayload, isDuplication?: boolean): Observable<any> {
    const url = isDuplication ? '/trips/duplicate' : '/trips';
    return this._tripsCommunication.trips$.pipe(
      take(1),
      switchMap(trips => this._httpClient.post<any>(environment.baseUrl + url, trip).pipe(
        tap(response => {
          this.getTripById(response.tripId).subscribe((createdTripById: Trip) => {
            if (response.success) {
              const resultTrips = this._tripsCommunication.getTripsValue;
              trips.shift();
              this._tripsCommunication.nextTrips([createdTripById, ...resultTrips]);
            }

            return response;
          });
        })
      ))
    );
  }

  getForecast(tripId): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.baseUrl + `/trips/${tripId}/forecast`);
  }

  getTripPassengers(
    tripId: number,
    page: number = 1,
    size: number = 1000,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    filter: string = ''
  ): Observable<Response<Passenger[]>> {
    return this._httpClient
      .get<Response<any>>(environment.baseUrl + `/trips/${tripId}/passengers/`, {
        params: {
          page: '' + page,
          limit: '' + size,
          sort,
          order,
          filter,
          tripId: '' + tripId
        }
      })
      .pipe(
        tap((tripPassengersResponse: Response<any>) => {
          this._tripsCommunication.nextTripPassengersPagination({
            length: tripPassengersResponse.total,
            size: size,
            page: page,
            lastPage: Math.ceil(tripPassengersResponse.total / size),
            startIndex: size * page,
            endIndex: size * page + Math.min(tripPassengersResponse.total, size)
          });
          this._tripsCommunication.nextTripPassengers(tripPassengersResponse.data);
        })
      );
  }

  verifyPassenger(tripId: number, tripGroupId: number = -1, name: string, lastName: string) {
    return this._httpClient.get<Response<any>>(environment.baseUrl + `/trips/${tripId}/groups/${tripGroupId}/verify`, {
      params: {
        name,
        lastName
      }
    }).pipe(tap(response => {
      return response
    }))
  }

  importPassengers(tripId: number, formData: FormData): Observable<{
    data: ImportPassengers,
    errors: [],
    success: boolean
  }>{
    return this._httpClient.post<{
      data: ImportPassengers,
      errors: [],
      success: boolean
    }>(environment.baseUrl + `/trips/${tripId}/passengers/import`, formData);
  }

  getCodes(tripId): Observable<Response<TripCode>> {
    return this._httpClient.get<Response<TripCode>>(environment.baseUrl + `/trips/${tripId}/codes`);
  }

  createCode(tripId: number, req: CreateTripCodeRequest): Observable<SingleResponse<number>>{
    return this._httpClient.post<SingleResponse<number>>(environment.baseUrl + `/trips/${tripId}/codes`, req)
  }

  updateCode(tripId: number, tripCodeId: number, req: CreateTripCodeRequest): Observable<SingleResponse<string>>{
    return this._httpClient.put<SingleResponse<string>>(environment.baseUrl + `/trips/${tripId}/codes/${tripCodeId}`, req)
  }

  deleteCode(tripId: number, tripCodeId: number): Observable<SingleResponse<string>>{
    return this._httpClient.delete<SingleResponse<string>>(environment.baseUrl + `/trips/${tripId}/codes/${tripCodeId}`)
  }

  getTripInformation(tripId: number, role: 'passengers' | 'coordinators'): Observable<SingleResponse<TripInformation>> {
    return this._httpClient.get<SingleResponse<TripInformation>>(environment.baseUrl + `/trips/${tripId}/${role}/info`);
  }

  createTripInformation(tripId: number, role: 'passengers' | 'coordinators', payload: TripInformation) {
    return this._httpClient.post(environment.baseUrl + `/trips/${tripId}/${role}/info`, payload);
  }
}
