import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstLetter'
})
export class FirstCapPipe implements PipeTransform {

  transform(value: string | String): string | String {
    if (!value) return value;
    return value[0].toUpperCase() + value.slice(1).toLowerCase();
  }

}
