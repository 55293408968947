import { SelectionModel } from "@angular/cdk/collections";
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { merge, Observable, Subject } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";

import { Provider, Pagination } from "../../../types/objects";
import { MODAL_DIALOG_LIST_PAGE_SIZES, MODAL_DIALOG_LIST_PAGE_SIZE_DEFAULT } from '../../../shared/constants';

import { ProvidersCommunicationService } from "../../../services";
import { ProvidersService } from "../../../services";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Response } from "app/types/response-types";

@Component({
  selector: "select-provider-dialog",
  templateUrl: "./select-provider-dialog.component.html",
  styleUrls: ["./select-provider-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SelectProviderDialogComponent implements OnInit {
  private _unsubscribeAll: Subject<void>;

  isLoading: boolean = false;
  searchProviderInputControl: FormControl;
  selection = new SelectionModel<Provider>(false, []);
  
  @ViewChild(MatTable) table: MatTable<any>;
  providersTableColumns: string[] = ["select", "name", "internalCode", "country"];

  dataSource: MatTableDataSource<Provider>;
  providers: Provider[] = [];

  filterString = "";
  pageIndex = 0;
  paginationLength = 0;
  pageSize = 10;
  pageSizeOptions = [10, 25, 50, 100];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _providersService: ProvidersService,
    public matDialogRef: MatDialogRef<SelectProviderDialogComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _providersCommunication: ProvidersCommunicationService
  ) {
    this._unsubscribeAll = new Subject();
    this.searchProviderInputControl = new FormControl();
  }

  ngOnInit(): void {
    this.onSearchChange();
    this.getProvidersCurrentPagination();
  }

  onSearchChange(): void {
    this.searchProviderInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(200)
      )
      .subscribe((value) => {
        this.pageIndex = 0;
        this.paginationLength = 0;
        this.filterString = value;
        this.getProvidersCurrentPagination()
      });
  }

  getProvidersCurrentPagination(): void {
    this.getProviders({
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      length: 0,
    })
  }

  getProviders(event: PageEvent): void {
    this.isLoading = true;
    this._changeDetectorRef.detectChanges();

    this._providersService.getProviders(event.pageIndex + 1, event.pageSize, "", "", this.filterString)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((providers: Response<Provider>) => {
        this.isLoading = false;
        this.paginationLength = providers.total;
        this.providers = providers.data;

        this._changeDetectorRef.detectChanges();
      });
  }

  cancel(): void {
    this.matDialogRef.close(null);
  }

  accept(): void {
    const selectedProvider: Provider = this.selection?.selected[0];
    this.matDialogRef.close({
      label: selectedProvider?.name,
      value: selectedProvider?.providerId
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
