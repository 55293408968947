<div
  id="avatar-wrapper"
  class="mr-1"
  [style.width]="size"
  [style.height]="size"
  [style.border-radius]="radius ? '50%' : '10px'"
>
  <img *ngIf="src && src.indexOf('profile-pic') === -1; else defaultAvatar" [src]="src" alt="avatar" />

  <ng-template #defaultAvatar>
    <div
      id="default-avatar"
      [style.width]="size"
      [style.height]="size"
      [style.font-size]="fontSize !== '' ? fontSize : '16px'"
    >
      {{ text?.charAt(0).toUpperCase() }}
    </div>
  </ng-template>
</div>
