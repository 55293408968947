<!-- Button -->
<button class="icon-button" mat-mini-fab [matMenuTriggerFor]="userActions">
    <span class="avatar">
        <img *ngIf="showAvatar && user.photo_url" [src]="user.photo_url" draggable="false">
        <img *ngIf="!showAvatar || !user.photo_url" draggable="false" src="assets/images/profile-pic.svg"
            class="shadow-md border-white border-2" />
    </span>
</button>

<mat-menu class="user-actions-menu" [xPosition]="'before'" #userActions="matMenu">
    <div class="flex items-center gap-4 py-2 px-4" (click)="$event.stopPropagation()">
        <span class="avatar rounded-full shadow-lg" style="border-color: #3e6fb9">
            <img *ngIf="showAvatar && user.photo_url" [src]="user.photo_url" draggable="false" class="w-14 h-14">
            <img *ngIf="!showAvatar || !user.photo_url" src="assets/images/profile-pic.svg" draggable="false" class="w-12 h-12" />
        </span>
        <div class="grid gap-1">
            <div class="font-medium">{{user.name}} {{user.last_name}}</div>
            <div class="text-secondary">{{user.email}}</div>
        </div>
    </div>
    <mat-divider class="my-2" (click)="$event.stopPropagation()"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>Cerrar sesión</span>
    </button>
</mat-menu>