import { Pipe, PipeTransform } from '@angular/core';
import { ItineraryEventTypeEnum } from '../enums/itinerary-event-type.enum';

@Pipe({
  name: 'itineraryEventType'
})
export class ItineraryEventTypePipe implements PipeTransform {

  transform(value: string): string {
    return ItineraryEventTypeEnum[value];
  }

}
