import { catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from '../../layout/common/user/user.service';
import { Response } from 'app/types/response-types';
import { ReSendVerificationCodeRequest } from 'app/types/request-types/auth/re-send-verification-code-request.type';
import { ValidateUserRequest } from 'app/types/request-types/auth/validate-user-request.type';
import { ChangePasswordRequest } from 'app/types/request-types/auth/change-password-request.type';

@Injectable()
export class AuthService {
  // Private
  private _authenticated: boolean;

  private user: any;

  constructor(
    private _httpClient: HttpClient,
    private userService: UserService
  ) {
    this._authenticated = false;
  }

  set accessToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  set authenticated(authenticated: boolean) {
    this._authenticated = authenticated;
  }

  set chatEnterpriseId(chatEnterpriseId: string) {
    localStorage.setItem('chatEnterpriseId', chatEnterpriseId);
  }

  get chatEnterpriseId(): string {
    return localStorage.getItem('chatEnterpriseId');
  }

  set enterpriseId(enterpriseId: string) {
    localStorage.setItem('enterprise_id', enterpriseId);
  }

  get enterpriseId(): string {
    return localStorage.getItem('enterprise_id');
  }

  signIn(credenciales: { email: string, password: string, login_type: string }): Observable<any> {
    if (this._authenticated) {
      return throwError('ya logueado.');
    }

    return this._httpClient.post(environment.baseUrl + '/public/login', credenciales).pipe(
      switchMap((response: Response<any>) => {
        if (response.success) {
          if (response.data["accessToken"]) {
            this.accessToken = response.data["accessToken"];
            this.enterpriseId = response.data["enterprises"].filter((enterprise) => enterprise)[0].enterpriseId;
            this._authenticated = true;
            return of(response);
          }
        }

        return throwError(response);
      })
    );
  }

  validateUser(validation: { email: string, code: string }): Observable<any> {
    return this._httpClient.post(environment.baseUrl + '/public/validateUser', validation).pipe(
      switchMap((response: any) => {
        if (response.status) {
          if (response.status === 'OK') {
            return of(response);
          }
        }

        return throwError(response);

      })
    );
  }

  signUp(credenciales: { name: string, last_name: string, email: string, password: string, phone: string, country: string }): Observable<any> {
    return this._httpClient.post(environment.baseUrl + '/public/createUser', credenciales).pipe(
      switchMap((response: any) => {
        if (response.status === 'OK') {
          return of(response);
        }

        return throwError(response);
      })
    );
  }

  signOut(): Observable<any> {
    localStorage.removeItem('chatAccessToken');
    localStorage.removeItem('access_token');
    localStorage.removeItem('enterprise_id');

    this._authenticated = false;

    return of(true);
  }

  check(): Observable<any> {
    return this._httpClient.get(environment.baseUrl + '/user/me/console').pipe(
      switchMap((response: any) => {
        if (response.error) {
          return of(false);
        } else {
          this.userService.user = response.data.user;
          return of(true);
        }
      }),
      catchError((e) => {
        return of(false);
      })
    );
  }

  forgotPassword(reSendVerificationCodeReq: ReSendVerificationCodeRequest): Observable<Response<any>> {
    return this._httpClient.post<Response<any>>(
      environment.baseUrl + "/public/sendRecoverPassword",
      reSendVerificationCodeReq,
      {}
    );
  }

  validateCode(validateUserReq: ValidateUserRequest) {
    return this._httpClient.post(environment.baseUrl + "/public/validateUser", validateUserReq, {});
  }

  reSendVerificationCode(reSendVerificationCodeReq: ReSendVerificationCodeRequest): Observable<Response<any>> {
    return this._httpClient.post<Response<any>>(
      environment.baseUrl + "/public/sendValidationUser",
      reSendVerificationCodeReq,
      {}
    );
  }
  
  changePasswordWithCode(changePasswordReq: ChangePasswordRequest): Observable<Response<any>> {
    return this._httpClient.post<Response<any>>(environment.baseUrl + "/public/changePasswordWithCode", changePasswordReq, {});
  }

  sendTemporaryPassword(userId: any) {
    return this._httpClient.post<Response<any>>(environment.baseUrl + "/users/send-temporary-password",
    {
      userId
    })
  }

  me(): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.baseUrl + '/user/me/console')  
  }

  public getUser(): any {
    return this.user;
  }
}
