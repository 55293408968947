import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { Response, SingleResponse } from '../types/response-types';

@Injectable({
  providedIn: 'root'
})
export class AiService {
  constructor(
    private _httpClient: HttpClient
  ) { }

  help(destination: string, id: number): Observable<SingleResponse<string>> {
    return this._httpClient.get<SingleResponse<string>>(environment.baseUrl + `/ai/`, {
      params: {
        "destination": destination,
        "id": String(id)
      }
    })
  }
  
}
