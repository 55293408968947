import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboard'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed in user to the '/dashboard'
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'prueba', 
    loadChildren: () =>
      import('app/modules/self-service/self-service.module').then(m => m.SelfServiceModule)
  },
  // Auth routes (guest)
  {
    path: '',
    canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            m => m.AuthConfirmationRequiredModule
          )
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
      }
    ]
  },

  // Auth routes (logged in)
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)
      }
    ]
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
      }
    ]
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver
    },
    children: [
      // Example
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'enterprise',
        loadChildren: () => import('app/modules/admin/enterprise/enterprise.module').then(m => m.EnterpriseModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('app/modules/admin/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'providers',
        loadChildren: () => import('app/modules/admin/providers/providers.module').then(m => m.ProvidersModule)
      },
      {
        path: 'services',
        loadChildren: () => import('app/modules/admin/services/services.module').then(m => m.ServicesModule)
      },
      {
        path: 'trips',
        loadChildren: () => import('app/modules/admin/trips/trips.module').then(m => m.TripsModule)
      },
      {
        path: 'monitor',
        loadChildren: () => import('app/modules/admin/monitor/monitor.module').then(m => m.MonitorModule)
      },
      {
        path: 'metrics',
        loadChildren: () => import('app/modules/admin/metrics/metrics.module').then(m => m.MetricsModule)
      },
      {
        path: 'store',
        loadChildren: () => import('app/modules/admin/store/store.module').then(m => m.StoreModule)
      },
      {
        path: 'notifications',
        // loadChildren: () => import('app/modules/admin/notifications/notifications.module').then(m => m.NotificationsModule)
        loadChildren: () => import('app/modules/admin/scheduled-notifications/scheduled-notifications.module').then(m => m.ScheduledNotificationsModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'user-notifications',
        loadChildren: () => import('app/layout/common/user-notifications-detail/user-notifications-detail.module').then(m => m.UserNotificationsDetailModule)
      }

      // 404 & Catch all
      // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
      // {path: '**', redirectTo: '404-not-found'}
    ]
  }
];
