import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import QRCode from 'qrcode';

@Component({
  selector: 'show-trip-code-dialog',
  templateUrl: './show-trip-code-dialog.component.html',
  styleUrls: ['./show-trip-code-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShowTripCodeDialogComponent implements OnInit {
  code = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.code = data?.code ? data.code : this.code;
  }

  ngOnInit(): void {
    const opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      margin: 0,
      width: 1280,
      height: 1280,
      color: {
        dark:"#000",
        light:"#fff"
      }
    }

    let canvas = document.getElementById("canvas");
      QRCode.toCanvas(canvas, String(this.code), opts, function (error) {
    })
  }

  downloadImage(){
    const opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      margin: 0,
      width: 1280,
      height: 1280,
      color: {
        dark:"#000",
        light:"#fff"
      }
    }

    QRCode.toDataURL(String(this.code), opts, function (err, url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = "qr.png";
      link.click();
    })
  }
}
