<div class="flex items-center gap-3 w-full" (click)="_notificationsService.openNotification(notification)">
    <div class="rounded-full color-primary-bg flex items-center justify-center p-2">
      <mat-icon class="text-white pl-px">{{ notification.iconName }}</mat-icon>
    </div>
    <div class="flex flex-col w-fill-available">
      <div class="color-primary font-medium">{{ notification.title }}</div>
      <div class="text-secondary">{{ notification.content }}</div>
    </div>
    <button mat-icon-button (click)="closeSnackbar($event)">
        <mat-icon>close</mat-icon>
    </button>
  </div>