/* tslint:disable:max-line-length */
import { FeatureNavigationItem, TreoNavigationItem } from '@treo/components/navigation';

export const defaultNavigation: TreoNavigationItem[] = [
  {
    id: 'starter',
    title: 'Starter',
    subtitle: 'Treo Starter Kit',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'starter.example',
        title: 'Example component',
        type: 'basic',
        link: '/example'
      },
      {
        id: 'starter.dummy.1',
        title: 'Dummy menu item #1',
        type: 'basic'
      },
      {
        id: 'starter.dummy.2',
        title: 'Dummy menu item #1',
        type: 'basic'
      }
    ]
  }
];
export const compactNavigation: TreoNavigationItem[] = [
  {
    id: 'starter',
    title: 'Starter',
    type: 'aside',
    icon: 'apps',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  }
];


export const futuristicNavigation: FeatureNavigationItem[] = [
  {
    id: 'viaxlab.dashboard',
    title: 'MENU.DASHBOARD',
    type: 'basic',
    icon: 'dashboard',
    link: '/dashboard'
  },
  {
    id: 'viaxlab.providers',
    title: 'MENU.PROVIDERS',
    icon: 'handshake',
    type: 'basic',
    link: '/providers'
  },
  {
    id: 'viaxlab.services',
    title: 'MENU.SERVICES',
    icon: 'room_service',
    type: 'basic',
    link: '/services'
  },
  {
    id: 'viaxlab.trips',
    title: 'MENU.TRIPS',
    icon: 'flight',
    type: 'basic',
    link: '/trips'
  },
  {
    id: 'viaxlab.monitor',
    title: 'MENU.MONITOR',
    icon: 'camera_front',
    type: 'basic',
    link: '/monitor',
  },
  {
    id: 'viaxlab.store',
    title: 'MENU.STORE',
    icon: 'store',
    type: 'basic',
    link: '/store',
    featureId: 7,
  },
  {
    id: 'viaxlab.metrics',
    title: 'MENU.METRICS',
    icon: 'table_chart',
    type: 'basic',
    link: '/metrics',
    featureId: 3,
  },
  {
    id: 'viaxlab.users',
    title: 'MENU.USERS',
    icon: 'person_pin',
    type: 'basic',
    link: '/users'
  },
  {
    id: 'viaxlab.notifications',
    title: 'MENU.NOTIFICATIONS',
    icon: 'notifications',
    type: 'basic',
    link: '/notifications',
    featureId: 14,
  },
  {
    id: 'viaxlab.clients',
    title: 'MENU.CLIENTS',
    icon: 'supervised_user_circle',
    type: 'basic',
    link: '/clients'
  }
];
export const horizontalNavigation: TreoNavigationItem[] = [
  {
    id: 'starter',
    title: 'Starter',
    type: 'group',
    icon: 'apps',
    children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  }
];
