import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { Language } from "../../types/objects/language.type"

const LNG_KEY = "SELECTED_LANGUAGE";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  selected: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private translate: TranslateService) {}

  setInitialAppLanguage(): void {
    const availableLanguages = ["en", "es", "it", "fr"];
    
    let language = "es";

    // if(!availableLanguages.includes(language)){
    //   language = "en";
    // }

    this.translate.setDefaultLang(language);
    let value = language;
    if (value) {
       this.setLanguage(value)
    } else {
      this.setLanguage(language)
    }
  }

  getLanguages(): Language[] {
    return [
      {
        text: "English",
        value: "en",
        selected: false,
      },
      {
        text: "Español",
        value: "es",
        selected: false,
      },
      {
        text: "Italiano",
        value: "it",
        selected: false,
      },
      {
        text: "Français",
        value: "fr",
        selected: false,
      },
    ];
  }

  getCurrentLanguage() {
    return "es";
  }

  setLanguage(lng: string): void {
    this.translate.use(lng);
    localStorage.setItem(LNG_KEY, lng);
  }
}
