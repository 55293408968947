import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const notificationsFormGroup = (formBuilder: FormBuilder): FormGroup => {
  const group = formBuilder.group({
    tripGroupIds: [null],
    roles: ['', Validators.required],
    title: ['', Validators.required],
    content: ['', Validators.required],

    sendingOption: ['', Validators.required],
    offsetReference: [null],
    reference: [null],
    timeOffset: [null],
  });

  const sendingOptionControl = group.get('sendingOption');

  const updateValidators = () => {
    if (sendingOptionControl?.value === 'now') {
      group.get('offsetReference')?.clearValidators();
      group.get('reference')?.clearValidators();
      group.get('timeOffset')?.clearValidators();

      // Set null values
      group.patchValue({
        offsetReference: null,
        reference: null,
        timeOffset: null
      });
    } else {
      group.get('offsetReference')?.setValidators(Validators.required);
      group.get('reference')?.setValidators(Validators.required);
      group.get('timeOffset')?.setValidators(Validators.required);
    }

    // After changing the validators, you need to call updateValueAndValidity() to update the validation status
    group.get('offsetReference')?.updateValueAndValidity();
    group.get('reference')?.updateValueAndValidity();
    group.get('timeOffset')?.updateValueAndValidity();
  };

  // Initial validator setup based on default form state
  updateValidators();

  // Subscribe to changes and update validators
  sendingOptionControl?.valueChanges.subscribe(updateValidators);

  return group;
};