import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'summaryTitle'
})
export class SummaryTitlePipe implements PipeTransform {

  transform(value: string): string {
    if (value === 'all') {
      return 'GROUPS.GROUP_BY.ALL';
    } else if (value === 'unassigned') {
      return 'GROUPS.GROUP_BY.UNASSIGNED';
    } else {
      return value;
    }
  }

}
