import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

export const SocialPostFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    groups: ['', {Validators: Validators.required}],
    description: ['', {}],
    tags: ['', {}],
    images: ['', {}]
  }, {
    validators: [validateImages, validateMultipleImages]
  });
};

export function validateImages(control: AbstractControl): ValidationErrors | null {
  const imagesControl = control.get('images').value;
  return imagesControl !== "" ? null : { invalid: true };
}

export function validateMultipleImages(control: AbstractControl): ValidationErrors | null {
  const groupsControl = control.get('groups').value;
  return groupsControl.length ? null : { invalid: true };
}