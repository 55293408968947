import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import {
  ConfirmDialogComponent,
  SelectProviderDialogComponent,
  ShowTripCodeDialogComponent,
} from './dialogs';

import { CatalogService, ModalService } from './services';

import { CardsListComponent } from './components/cards-list/cards-list.component';
import { PassengerCardComponent } from './components/passenger-card/passenger-card.component';
import { ChipComponent } from './components/chip/chip.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule, TooltipComponent } from '@angular/material/tooltip';

import {
  ItinerariesCommunicationService,
  ItinerariesService,
  ProvidersCommunicationService,
  ProvidersService,
  AlertsService
} from '../services';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TreoDateRangeModule } from '../../@treo/components/date-range';
import { SummaryCardComponent } from './components/summary-card/summary-card.component';
import { DayTypePipe, ItineraryEventTypePipe, IconTypePipe } from './pipes';
import { SummaryTitlePipe } from './pipes/summary-title.pipe';
import { TripGroupNamePipe } from './pipes/trip-group-name.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CreatePostModalComponent } from './dialogs/create-post-modal/create-post-modal.component'
import { MatChipsModule } from '@angular/material/chips';
import { GrabScrollDirective } from './directives/grab-scroll.directive';
import { QuillModule } from 'ngx-quill';
import { NgxImageCompressService } from "ngx-image-compress";
import { ConfirmAddPassengerComponent } from './dialogs/confirm-add-passenger/confirm-add-passenger.component';
import { HttpLoaderFactory } from 'app/app.module';
import { HttpClient } from '@angular/common/http';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { MatMenuModule } from '@angular/material/menu';
import { InfoChipComponent } from './components/info-chip/info-chip.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { ErrorStateComponent } from './components/error-state/error-state.component';
import { NotificationSnackbarComponent } from './components/notification-snackbar/notification-snackbar.component';
import { FirstCapPipe } from './pipes/first-cap.pipe';
import { AlertComponent } from './components/alert/alert.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { ImageSelectorOptionsComponent } from './components/image-selector/components/image-selector-options/image-selector-options.component';
import { UnsplashModalComponent } from './components/image-selector/components/unsplash-modal/unsplash-modal.component';
import { ImageUrlModalComponent } from './components/image-selector/components/image-url-modal/image-url-modal.component';
import { EnterpriseActivationsComponent } from 'app/modules/admin/enterprise/components/enterprise-activations/enterprise-activations.component';
import { PlanRestrictionsDirective } from './directives/plan-restrictions.directive';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    SelectProviderDialogComponent,
    CreatePostModalComponent,
    CardsListComponent,
    PassengerCardComponent,
    ChipComponent,
    SummaryCardComponent,
    DayTypePipe,
    FirstCapPipe,
    ItineraryEventTypePipe,
    IconTypePipe,
    SummaryTitlePipe,
    TripGroupNamePipe,
    AvatarComponent,
    GrabScrollDirective,
    ShowTripCodeDialogComponent,
    ConfirmAddPassengerComponent,
    EmptyStateComponent,
    InfoChipComponent,
    ErrorStateComponent,
    NotificationSnackbarComponent,
    FirstCapPipe,
    AlertComponent,
    ImageSelectorComponent,
    ImageSelectorOptionsComponent,
    UnsplashModalComponent,
    ImageUrlModalComponent,
    EnterpriseActivationsComponent,
    PlanRestrictionsDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBadgeModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSnackBarModule,
    MatMenuModule,
    MatListModule,
    TranslateModule,
    MatDividerModule,
    FileUploadModule,
    // TreoDateRangeModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    MatChipsModule,
    NgxMatSelectSearchModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          [{ 'align': [] }],
          ['blockquote'],
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['clean'],                                         // remove formatting button
          ['link', 'video']                         // link and image, video
        ]
      }
    })
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatDialogModule,
    MatCheckboxModule,
    SummaryCardComponent,
    CardsListComponent,
    PassengerCardComponent,
    ChipComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatDividerModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatBadgeModule,
    ItineraryEventTypePipe,
    IconTypePipe,
    DayTypePipe,
    FirstCapPipe,
    SummaryTitlePipe,
    AvatarComponent,
    GrabScrollDirective,
    NgxMatSelectSearchModule,
    QuillModule,
    EmptyStateComponent,
    ErrorStateComponent,
    ImageSelectorComponent,
    AlertComponent,
    EnterpriseActivationsComponent,
    PlanRestrictionsDirective
  ],
  providers: [
    ModalService,
    CatalogService,
    ProvidersService,
    ProvidersCommunicationService,
    ItinerariesService,
    ItinerariesCommunicationService,
    NgxImageCompressService,
    AlertsService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ]
})
export class SharedModule {
}
