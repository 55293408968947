import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'app/services';

@Component({
  selector: 'app-image-url-modal',
  templateUrl: './image-url-modal.component.html',
  styleUrls: ['./image-url-modal.component.scss']
})
export class ImageUrlModalComponent implements OnInit {

  selectedUrlImage = '';

  constructor(
    private _alertService: AlertsService,
    public matDialogRef: MatDialogRef<ImageUrlModalComponent>
  ) { }

  ngOnInit(): void {
  }

  confirmImageUrl(url: string) {
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

    if (!url.match(urlRegex)) return this._alertService.showSnackbarAlert('Ingresa una URL valida');

    this.isImageUrl(url)
      .then((isValidImageUrl) => { 
        if (isValidImageUrl) {
          this.selectedUrlImage = url;
        } else {
          this._alertService.showSnackbarAlert('La URL no contiene una imagen válida');
        }
      });
  }

  isImageUrl(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  addImages() {
    this.matDialogRef.close(this.selectedUrlImage);
  }

}
