  <div class="header">
    <span>{{ "GROUPS.MODALS.ADD_PASSENGER.CONFLICT.CONFLICT" | translate }}</span>
  </div>
  <div class="title">
    <p class="title">{{ "GROUPS.MODALS.ADD_PASSENGER.CONFLICT.CONFLICT_TITLE" | translate }}</p>
  </div>
  <div class="message">
    <p>{{ "GROUPS.MODALS.ADD_PASSENGER.CONFLICT.CONFLICT_TEXT" | translate }}</p>
  </div>
  <div class="passengers">
    <div class="card">
      <p class="title">{{ "SHARED.DIALOGS.PAX_TO_ADD" | translate }}:</p>
      <div class="pic">
        <img [src]="
        'https://viaxlab-images.s3.us-east.cloud-object-storage.appdomain.cloud/1/42207376-f0d8-448d-b7d6-7ed905a466d3.jpg'" alt="">
      </div>
      <div class="data">
        <p class="field name">{{ this.passengerToAdd.name }} {{ this.passengerToAdd.last_name }}</p>
        <p class="field email">{{ this.passengerToAdd.email }}</p>
        <p class="field phone">{{ this.passengerToAdd.phone }}</p>
      </div>
    </div>
    <div class="card">
      <p class="title">{{ "SHARED.DIALOGS.EXISTING_PAX" | translate }}:</p>
      <div class="pic">
        <img [src]="
        'https://viaxlab-images.s3.us-east.cloud-object-storage.appdomain.cloud/1/42207376-f0d8-448d-b7d6-7ed905a466d3.jpg'" alt="">
      </div>
      <div class="data">
        <p class="field name">{{ this.passengerToReplace.name }} {{ this.passengerToReplace.last_name }}</p>
        <p class="field email">{{ this.passengerToReplace.email }}</p>
        <p class="field phone">{{ this.passengerToReplace.phone }}</p>
      </div>
    </div>
  </div>
  <div class="passenger-buttons">
    <button class="mat-focus-indicator mat-flat-button mat-button-base mat-primary"
            mat-flat-button
            (click)="replacePassenger(this.passengerToReplace.passengerId)">
      <p class="mat-button-wrapper" >{{ "SHARED.DIALOGS.KEEP_NEW_PAX" | translate }}</p>
    </button>
    <button class="mat-focus-indicator mat-flat-button mat-button-base mat-primary"
            mat-flat-button
            (click)="cancel()">
      <p class="mat-button-wrapper" >{{ "SHARED.DIALOGS.KEEP_EXISTING_PAX" | translate }}</p>
    </button>
  </div>
  <div class="buttons flex justify-end">
    <button class="mat-focus-indicator mat-button mat-button-base mat-warn mr-2"
            mat-button
            (click)="cancel()">
      <span class="mat-button-wrapper" >{{ "CANCEL" | translate }}</span>
    </button>
    <button class="mat-focus-indicator mat-flat-button mat-button-base mat-primary"
            mat-flat-button
            (click)="accept()">
      <span class="mat-button-wrapper" >{{ "SHARED.DIALOGS.KEEP_BOTH" | translate }}</span>
    </button>
  </div>