import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';

export const storeFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    startDate: [''],
    endDate: [''],
    product: [''],
    status: [[]],
    passenger: [''],
    hotel: [''],
    paymentType: [''],
    trip: [''],
    tripGroup: [''],
    coordinator: [''],
    tripEndDate: ['']
  });
};

// export const dateRangeValidator: ValidatorFn | null = (fg: FormGroup) => {
//   const startDate = new Date(fg.controls.startDate.value);
//   const endDate = new Date(fg.controls.endDate.value);
//   return endDate >= startDate ? null : { error: true };
// };
