import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { EnterpriseService } from '../../services/enterprise.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   *
   * @param {AuthService} _authService
   */
  constructor(
    private _authService: AuthService,
    private enterpriseService: EnterpriseService
  ) {
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request objectpassenger
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken) {
      newReq = req.clone({
        headers: req.headers
          .set('Authorization', this._authService.accessToken)
          .set('X-EnterpriseId', '' + (this._authService.enterpriseId ?? 'ERROR'))
      });
      if (req.url.indexOf(environment.chatUrl) > -1) {
        newReq = req.clone({
          headers: req.headers
            .set('Authorization', this._authService.accessToken)
            .set('X-EnterpriseId', '' + (this._authService.enterpriseId ?? 'ERROR'))
            .set('X-ChatEnterpriseId', this._authService.chatEnterpriseId)
        });
      }
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {

        console.log(error);

        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          this._authService.signOut();

          // Reload the app
          location.reload();
        }

        return throwError(error);
      })
    );
  }
}
