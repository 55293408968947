import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { UserNotification } from '../../../types/objects/notifications.type';
import { NotificationsService } from 'app/services';

@Component({
  selector: 'app-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {
  
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public notification: UserNotification,
    private snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>,
    public _notificationsService: NotificationsService,
  ) { }

  openNotification(notification: UserNotification) {
    this.snackBarRef.dismiss();
    this._notificationsService.openNotification(notification);
  }

  closeSnackbar(event: Event): void {
    event.stopPropagation();
    this.snackBarRef.dismiss();
  }
}
