import { ChaUserDto } from './chat-user.dto';

export class GetChatUserDto extends ChaUserDto {
constructor(page?: number, limit?: number, field?: string, order?: string, filter?: string) {
  super();
  this.page = page || 1;
  this.limit = limit || 10;
  this.field = field || 'name';
  this.order = order || 'asc';
  this.filter = filter || "";
}
}
